import { apiServiceRequest } from "Utils/axiosInstance";

//currency Service
const loginService = async (data, notify) => {
  const res = await apiServiceRequest({
    url: `/login`,
    method: "post",
    data: data,
    notify: notify,
  });
  return res;
};

export default loginService;
