import React, { useEffect, useState } from "react";
import {
  useRedirect,
  useTranslate
} from "react-admin";
import { useLocation, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Header from "Components/Header";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import BackButton from "Components/BackButton";
import Loader from "Components/Loader";
import ProfilePic from "Components/ProfilePic";
import country from "Services/countryService";
import userDetails from "Services/userDetailsServices";
import updateUserDetails from "Services/updateUserDetailService";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import MyTeam from "../MyTeam/MyTeam";
import { getTeamList } from "Services/teamService";

const ManageDarglobal = ({ notification }) => {
  const [formData, setFormData] = useState({});
  const [countryList, setCountryList] = useState([]);
  const [companyCountry, setCompanyCountry] = useState();
  const [loading, setLoading] = useState(false);
  const [countryListLoading, setCountryListLoading] = useState(false);
  const [userDetailsLoading, setuserDetailsLoading] = useState(true);
  const [statusLoading, setStatusLoading] = useState(false);
  const [picture, setPicture] = useState(null);
  const [teamList, setTeamList] = useState([]);
  const [teamLoading, setTeamLoading] = useState(true);
  const [value, setValue] = useState("1");
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);

  const redirect = useRedirect();
  const navigate = useNavigate();
  const location = useLocation();
  const translate = useTranslate();

  const setCompanyProfile = (resData) => {
    setPicture(resData?.company_profile);
  };

  useEffect(() => {
    apiListDetails();
  }, []);

  const apiListDetails = async () => {
    const userDetail = await callUserDetails(setuserDetailsLoading);
    if (((userDetail?.user_type === 1) || (userDetail?.user_type === 2 && userDetail?.role === "sales_person"))) {
      navigate("/home");
    } else {
      country(setCountryList, setCountryListLoading, notification);
      callTeamList(setTeamLoading);
    }
  }

  const callTeamList = (loading, page) => {
    getTeamList(loading, teamList, setTeamList, setCount, page, notification);
  }

  const callUserDetails = async (loading) => {
    if (location?.pathname?.includes("/my-team")) {
      setValue("2");
    } else {
      setValue("1");
    }
    return userDetails(loading, setFormData, setCompanyProfile, notification);
  };

  // Get the value of the country according to the country code
  useEffect(() => {
    if (formData?.company_country) {
      const countryName = countryList?.find(
        (country) => country?.country_code === formData?.company_country
      )?.country_name;
      setCompanyCountry(countryName);
    }
  }, [formData, countryList]);

  useEffect(() => {
    document.body.classList.add(
      "profile-page"
    );
    return () => {
      document.body.classList.remove(
        "profile-page"
      );
    };
  }, []);

  const uploadPicture = (pictureFile) => {
    const form_data = new FormData();
    form_data.append("company_profile", pictureFile);
    ((!pictureFile && picture) || pictureFile) &&
      updateUserDetails(
        setLoading,
        form_data,
        notification,
        "",
        pictureFile ? "ra.notification.companyProfileSuccess" : "ra.notification.companyProfileRemove",
        callUserDetails
      );
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    // scrolling to top when tab changes as per design team
    window.scrollTo(0, 0);
  };

  return (
    <>
      {(userDetailsLoading || countryListLoading || loading || teamLoading || statusLoading) && <Loader />}
      <Header picture={formData?.profile} pictureLoading={userDetailsLoading} notification={notification} userData={formData} email={formData?.email} />
      <BackButton />
      <Container className="company-container manage-dar-panel profile-page-all mt-0">
        <Container className="company-details manage-global-panel">
          <Grid
            className="form-panel profile-form-panel profile-details"
            sx={{ width: "100%" }}
          >
            <Grid container className="profile-top-panel">
              <ProfilePic
                picture={picture}
                setPicture={setPicture}
                uploadPicture={uploadPicture}
                notification={notification}
              />
              <Typography className="profile-top-info" variant="div">
                <Typography variant="h3">{formData?.company_name}</Typography>
              </Typography>
            </Grid>
            <Grid container className="profile-bottom-panel">
              <Box sx={{ width: "100%", typography: "body1" }}>
                <TabContext value={value}>
                  <Box sx={{ borderBottom: 1, borderColor: "#E9E9E9" }}>
                    <TabList onChange={handleChange} aria-label="Manage dar Global tab">
                      <Tab label={translate("ra.fieldName.companyDetails")} value="1" onClick={() => navigate("/manage-darglobal", {replace : true})} />
                      <Tab label={translate("ra.fieldName.myTeam")} value="2" onClick={() => navigate("/my-team", {replace : true})} />
                    </TabList>
                  </Box>
                  {!userDetailsLoading && !teamLoading &&
                    <>
                      <TabPanel className="company-details-info personal-details " value="1">
                        {/* Company Name */}
                        <Grid container spacing={0}>
                          <Typography variant="subtitle1">{translate("ra.fieldName.companyName")}</Typography>
                          <Typography>
                            {formData?.company_name || "-"}
                          </Typography>
                        </Grid>

                        {/* Trade License Number */}
                        <Grid container spacing={0}>
                          <Typography variant="subtitle1">
                            {translate("ra.fieldName.tradeNo")}
                          </Typography>
                          <Typography>
                            {formData?.trade_license_number || "-"}
                          </Typography>
                        </Grid>

                        {/* Brokerage Certificate Number */}
                        <Grid container spacing={0}>
                          <Typography variant="subtitle1">
                            {translate("ra.fieldName.brokerageNo")}
                          </Typography>
                          <Typography>
                            {formData?.brokerage_certificate_number || "-"}
                          </Typography>
                        </Grid>

                        {/* Tax Registration Number */}
                        <Grid container spacing={0}>
                          <Typography variant="subtitle1">
                            {translate("ra.fieldName.taxNo")}
                          </Typography>
                          <Typography>
                            {formData?.tax_registration_number || "-"}
                          </Typography>
                        </Grid>
                        
                        <Typography variant="h4">Contact details</Typography>
                        {/* Authorized person name  */}
                        <Grid container spacing={0}>
                          <Typography variant="subtitle1">
                            {translate("ra.fieldName.authorizedName")}
                          </Typography>
                          <Typography>
                            {formData?.authorized_signatory_corporate_title || ""}&nbsp;
                            {formData?.authorized_signatory_corporate_first_name || ""}
                            &nbsp;
                            {formData?.authorized_signatory_corporate_last_name || ""}
                            &nbsp;
                          </Typography>
                        </Grid>

                        {/* Company address  */}
                        <Grid container spacing={0}>
                          <Typography variant="subtitle1">{translate("ra.fieldName.company_address")}</Typography>
                          <Typography>
                            {formData?.address && `${formData?.address}, `}
                            {formData?.city && `${formData?.city}, `}
                            {companyCountry || ""}
                            {!formData?.address &&
                              !formData?.city &&
                              !companyCountry &&
                              "-"}
                          </Typography>
                        </Grid>

                        {/*  Mobile */}
                        <Grid container spacing={0}>
                          <Typography variant="subtitle1">{translate("ra.fieldName.mobileNo")}</Typography>
                          <Typography>
                            {formData?.company_mobile_no_country_code &&
                              `+(${formData?.company_mobile_no_country_code}) `}
                            {formData?.company_mobile_no || ""}
                            {!formData?.company_mobile_no_country_code &&
                              !formData?.company_mobile_no &&
                              "-"}
                          </Typography>
                        </Grid>

                        {/* Email */}
                        <Grid container spacing={0}>
                          <Typography variant="subtitle1">{translate("ra.fieldName.emailAddress")}</Typography>
                          <Typography>
                            {formData?.email_address}
                          </Typography>
                        </Grid>
                      </TabPanel>
                      <TabPanel className="flex-column team-table-section" value="2">
                        <MyTeam
                          teamList={teamList}
                          setTeamList={setTeamList}
                          notify={notification}
                          navigate={navigate}
                          callTeamList={callTeamList}
                          count={count}
                          page={page}
                          setPage={setPage}
                          translate={translate}
                          userData={formData}
                          setStatusLoading={setStatusLoading}
                        />
                      </TabPanel>
                    </>
                  }
                </TabContext>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </>
  );
};

export default ManageDarglobal;
