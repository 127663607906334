import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useLogin, useRedirect, useTranslate, useStore } from "react-admin";
import { Button, CardContent, Typography, Grid, Divider } from "@mui/material";
import image from "../../images/brokers_image.png";
import logo from "../../images/logo.svg";
import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core";
import { emailPattern } from "Utils/regex-patterns";
import InputField from "Components/InputField";
import gtagEventFire from "Utils/ga4EventTrigger";
import useWebVitals from "Utils/useWebVitals";

const Login = ({ setOracle_status, notification }) => {
  const [user_type, setUser_type] = useStore("user_type");
  const [rememberMe, setRememberMe] = useState(false);
  const login = useLogin();
  const redirect = useRedirect();
  const translate = useTranslate();
  const form = useForm({
    mode: "onChange",
  });
  const { register, handleSubmit, formState } = form;
  const { errors } = formState;
  const pageType = "Login";
  useWebVitals(pageType);

  const onSubmit = (data) => {
    const { email, password } = data;
    gtagEventFire("sign-in", {
      user_email: email
    })
    login({ email, password, rememberMe, setOracle_status, notification }).catch(
      (error) => notification(error?.message, {type : "error"})
    );
  };

  const handleRegister = () => {
    gtagEventFire("sign-up");
    setUser_type(2);
    //redirect("/roles");
    redirect("/register-agency");
  };

  const handleChange = () => {
    setRememberMe(!rememberMe);
  };

  return (
    <>
      <Grid
        className="form-panel height100 form-fixed"
        container
        sx={{ minHeight: "100vh", width: "100%" }}
      >
        <Grid
          xs={{ display: "none" }}
          style={{ backgroundImage: `url(${image})` }}
          md={6}
          className="text-heading-section"
        >
          <Typography className="main-heading">
            {translate("ra.content.welcome")} {<br />} {translate("ra.content.dargloabal")} {<br />}{" "}
            <span>{translate("ra.content.network")}{<br />} {translate("ra.content.agent")}</span>
          </Typography>
          <Typography className="divider-separator">
            <span></span>
          </Typography>
          <Typography className="sub-heading">
            {translate("ra.content.realEstate")}
          </Typography>
          <Typography className="main-info">
            {translate("ra.content.joinAgents")}
          </Typography>
        </Grid>
        <Grid
          item
          className="first-login-section"
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          <CardContent>
            <Typography
              className="contact-link"
              onClick={() => {
                redirect("/contact-us");
                gtagEventFire("contact-us");
              }}
            >
              {translate("ra.content.contact")}
            </Typography>

            <img src={logo} className="login-logo" alt="Dar logo" />

            <Typography className="form-user-name">{translate("ra.content.portal")}</Typography>

            <Grid container spacing={0}>
              <InputField 
                name={translate("ra.fieldName.emailAddress")}
                titleClass="form-label"
                className="form-control"
                register={register} 
                errors={errors} 
                type="email"
                state="email"
                message={translate("ra.validation.emailError")}
                pattern={{
                  value: emailPattern,
                  message: translate("ra.validation.validEmail")
                }}
                placeholder={translate("ra.placeholder.email")}
                hide={true}
              />
            </Grid>
            <Grid className="mb0" container spacing={0}>
              <InputField 
                name={translate("ra.fieldName.password")}
                titleClass="form-label"
                className="form-control"
                register={register} 
                errors={errors} 
                type="password"
                state="password"
                message={translate("ra.validation.passwordError")}
                placeholder={translate("ra.placeholder.password")}
                hide={true}
              />
            </Grid>

            <FormGroup className="keep-me-info">
              <FormControlLabel
                control={
                  <Checkbox checked={rememberMe} onChange={handleChange} />
                }
                label={translate("ra.placeholder.loggedIn")}
              />
            </FormGroup>

            <Typography
              className="forgot-password-text"
              onClick={() => {
                redirect("/forgot-password");
              }}
            >
              {translate("ra.fieldName.forgotPassword")}
            </Typography>

            <Button
              className="sign-in-button"
              onClick={handleSubmit(onSubmit)}
              variant="contained"
              disableElevation
            >
              <Typography>{translate("ra.button.signIn")}</Typography>
            </Button>
            <Divider
              className="login-devider"
              sx={{
                marginBottom: "20px",
                marginTop: "15px",
                marginLeft: "0",
                marginRight: "0",
              }}
              variant="middle"
            >
              {translate("ra.fieldName.OR")}
            </Divider>
            <Typography
              sx={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "14px",
                lineHeight: "20px",
                color: "#081C2B",
                paddingBottom: "18px",
              }}
            >
              {translate("ra.content.notAgent")}
            </Typography>
            <Button
              className="register-now-button"
              onClick={handleRegister}
              variant="outlined"
            >
              {translate("ra.button.register")}
            </Button>
          </CardContent>
        </Grid>
      </Grid>
    </>
  );
};

export default Login;
