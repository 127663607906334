import React, { useState, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/KeyboardArrowDown";
import AccordianContent from "./AccordianContent";

export default function ControlledAccordions({
  val,
  data,
  setData,
  primary,
  setPrimary,
  idx,
  notification,
  ownership,
  setOwnership
}) {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  let keys = Object.keys(val);

  return (
    <Accordion
      className="assign-accordian"
      expanded={expanded === "panel1"}
      onChange={handleChange("panel1")}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Typography sx={{ color: "text.secondary" }}>{keys}</Typography>
      </AccordionSummary>
      <AccordianContent
        val={val}
        idx={idx}
        keys={keys}
        data={data}
        setData={setData}
        primary={primary}
        setPrimary={setPrimary}
        notification={notification}
        ownership={ownership}
        setOwnership={setOwnership}
      />
    </Accordion>
  );
}
