import React, { useEffect, useState } from "react";
import { SingleRangeSlider } from "Components/SingleRangeSlider";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { useStore, useTranslate } from "react-admin";
import AccordianCheckbox from "./AccordionCheckbox";

const AccordianContent = ({
  val,
  keys,
  data,
  setData,
  primary,
  setPrimary,
  idx,
  notification,
  ownership,
  setOwnership
}) => {
  const translate = useTranslate();

  const handleChange = (customerId, unit_no) => {
    const primaryArray = [...primary];
    const res = primaryArray?.map(pri => {
        if (unit_no === pri?.unit_no) {
            return {
                unit_no: pri?.unit_no,
                customerId: customerId
            }
        }
        else {
            return { ...pri }
        }
    })
    setPrimary(res)
  }

  return (
    <>
      {val[keys] &&
        val[keys]?.map((item, index) => {
          return (
            <AccordionDetails key={index}>
              <Typography component="div" className="assign-persentage-heading">
                <Typography component="p">
                  {item?.first_name} {item?.last_name}
                </Typography>
                <Typography component="p" onClick={() => handleChange(item?.customer_id, item?.unit_no)}>
                  <AccordianCheckbox
                    idx={idx}
                    primary={primary}
                    setPrimary={setPrimary}
                    is_primary={item?.is_primary_user}
                    customerId={item?.customer_id}
                    item={item}
                    handleChange={handleChange}
                  />{" "}
                  {translate("ra.fieldName.primaryOnly")}
                </Typography>
              </Typography>
              <Typography component="div" className="assign-persentage-info">
                <SingleRangeSlider
                  ownership={ownership}
                  setOwnership={setOwnership}
                  defaultValue={item?.ownership_percentage || 0}
                  max={100}
                  min={0}
                  item={item}
                  data={data}
                  setData={setData}
                  index={index}
                  keys={keys}
                  notification={notification}
                />
              </Typography>
            </AccordionDetails>
          );
        })}
    </>
  );
};

export default AccordianContent;
