import React, { useState } from "react";
import {
    useList,
    ListContextProvider,
    Datagrid,
    TextField,
    FunctionField,
} from "react-admin";
import {
    Grid,
    Button,
    Typography,
    useMediaQuery
} from "@mui/material";
import DetailFeild from "Components/DetailField/DetailField";
import ContactField from "Components/ContactField/ContactField";
import EditField from "Components/EditField/EditField";
import { updateMemberStatus } from "Services/teamService";
import InfiniteScroll from 'react-infinite-scroll-component'
import gtagEventFire from "Utils/ga4EventTrigger";

const MyTeam = ({ 
    teamList, 
    setTeamList, 
    notify, 
    navigate, 
    callTeamList, 
    count, 
    page, 
    setPage, 
    translate, 
    setStatusLoading ,
    userData
}) => {
    const [showMenu, setShowMenu] = useState(false);
    const [userId, setUserId] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(false);
    const listContext = useList({ data: teamList });
    const matches = useMediaQuery("(min-width: 768px)");

    const kebabMenuHandler = (id) => {
        if (userId === id) {
            clickAway();
        } else {
            setShowMenu(true);
            setUserId(id);
        }
    }

    const clickAway = () => {
        setShowMenu(false);
        setUserId(null);
    }

    const buttonComp = (btnName) => {
        return (
            <Button className="plus-icon" variant="text" onClick={() => navigate("/add-team") }>
                <i className="iconwasalt icon-plus" /> {btnName}
            </Button>
        )
    }

    const teamListUpdate = (value) => {
        clickAway();
        const teamUpdate = [...teamList]
        const index = teamList?.findIndex(list => list?.team_id === value?.id)
        const dataUpdate = {
            is_active: value?.is_active,
            status: parseInt(value?.is_active) === 1 ? "Active" : "Inactive"
        }
        const updateItem = { ...teamUpdate[index], ...dataUpdate }
        teamUpdate[index] = updateItem;
        setTeamList(teamUpdate);
    }

    const updateTeamStatus = (list) => {
        let message = "";
        if (list?.is_active === 1) {
            gtagEventFire("change_team_member_status", {
                user_email: userData?.email,
                status: translate("ra.button.deActivate"),
                new_member_email: list?.email
            });
            list.is_active = 0;
            message = "ra.notification.deactivateMember";
        } else {
            gtagEventFire("change_team_member_status", {
                user_email: userData?.email,
                status: translate("ra.button.activate"),
                new_member_email: list?.email
            })
            list.is_active = 1;
            message = "ra.notification.activateMember";
        }

        updateMemberStatus(
            setStatusLoading,
            list,
            notify,
            message,
            teamListUpdate
        )
    }

    const getTeamListData = async () => {
        if (!loading) {
            setLoading(true);
            if (teamList && teamList?.length < count) {
                callTeamList("", page + 1);
                setPage(page + 1);
            } else {
                setHasMore(false);
            }
            setLoading(false);
        }
    }

    return (
        <>
            <Grid className="team-table-top">
                <Typography variant="h3">{translate("ra.fieldName.totalMember")} ({count})</Typography>
                {buttonComp(translate("ra.button.add"))}
            </Grid>

            <InfiniteScroll
                dataLength={teamList && teamList?.length}
                next={getTeamListData}
                scrollThreshold={0.7}
                hasMore={hasMore}
                loader={loading && <div className="my-team-loader">{translate("ra.fieldName.loadMore")}</div>}
            >
                {teamList?.length > 0 ?
                    <>
                        {matches ?
                            <Grid className="my-team-panel">
                                <ListContextProvider value={listContext}>
                                    <Datagrid bulkActionButtons={false} setSort={false}>
                                        <DetailFeild label={translate("ra.fieldName.name")} roleClass="hide-class" />
                                        <DetailFeild label={translate("ra.fieldName.title_role")} nameClass="hide-class" boldClass="bold-class" />
                                        <ContactField label={translate("ra.fieldName.contactDetails")} />
                                        <FunctionField label={translate("ra.fieldName.status")} emptyText="-" render={(record) => 
                                            <TextField 
                                                className={`bold-class ${record?.status === "Active" ? "team-info-active" : "team-info-deactive"}`} 
                                                source="status" 
                                            />
                                        } />
                                        <EditField
                                            className="button-icon"
                                            source={translate("ra.fieldName.action")}
                                            onClickFn={kebabMenuHandler}
                                            showMenu={showMenu}
                                            userId={userId}
                                            clickAway={clickAway}
                                            updateTeamStatus={updateTeamStatus}
                                            translate={translate}
                                        />
                                    </Datagrid>
                                </ListContextProvider>
                            </Grid>
                            :
                            <Grid className="my-team-panel-mobile">
                                {teamList?.map((list, idx) =>
                                    <Grid key={idx} className="team-panel">
                                        <Grid className="team-dropdown">
                                            <EditField
                                                className="plus-icon"
                                                source="action"
                                                onClickFn={kebabMenuHandler}
                                                showMenu={showMenu} 
                                                userId={userId}
                                                clickAway={clickAway}
                                                list={list}
                                                updateTeamStatus={updateTeamStatus}
                                                translate={translate}
                                            />
                                        </Grid>
                                        <h3>{list?.first_name} {list?.last_name}</h3>
                                        <Typography className="designation-panel">{list?.designation} | +({list?.country_code}) {list?.mobile}</Typography>
                                        <Typography className="team-info text-capital"><span>{translate("ra.fieldName.role")}</span> <span>{list?.role}</span></Typography>
                                        <Typography className="team-info"><span>{translate("ra.fieldName.status")}</span> 
                                            <span className={`${list?.status === "Active" ? "team-info-active" : "team-info-deactive"}`}>{list?.status}</span>
                                        </Typography>
                                    </Grid>
                                )}
                            </Grid>
                        }
                    </>
                    :
                    <Typography className="no_record">{translate("ra.noData.team")}</Typography>
                }
            </InfiniteScroll>

            <Grid className="team-table-top mobile-add-team">
                {buttonComp(translate("ra.button.addMember"))}
            </Grid>
        </>
    );
};

export default MyTeam;