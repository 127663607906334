import Loader from "Components/Loader";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Button, Typography } from "@mui/material";
import InputField from "Components/InputField";
import { useTranslate } from "react-admin";
import { useForm } from "react-hook-form";
import { companyNamePattern } from "Utils/regex-patterns";
import AutoCompleteComponent from "Components/Common/AutoComplete";
import country from "Services/countryService";
import DatePickerComponent from "Components/Common/DatePicker";
import { expiryDate } from "Utils/maxMinDate";
import nationality from "Services/nationalityService";
import { getDateFormat } from "Utils/dateFormat";
import BasicDetailsForm from "Pages/BookinFlow/BasicDetailsForm";
import ResidentialDetails from "Pages/BookinFlow/ResidentialDetails";
import MultipleDocumentUpload from "Components/MultipleDocumentUpload";
import { customerCompanyService } from "Services/customerCreationandUpdation";
import { getLeadById } from "Services/leadService";

const CompanyCustomerForm = ({
  notification,
  customerType,
  project_id,
  units,
  id,
  setErrorVal,
  errorVal,
  progress,
  setProgress,
  setTabName,
  tabs,
  onClickBack,
  setBookingFailedPopup,
  bookingFailedPopup
}) => {
  const [customerCompanyLoading, setCustomerCompanyLoading] = useState(false);
  const [formData, setFormData] = useState(null);
  const [countryState, setCountryState] = useState(null);
  const [countryList, setCountryList] = useState([]);
  const [ccode, setccode] = useState(null);
  const [deleteId, setDeleteId] = useState([]);
  const [contactLoading, setContactLoading] = useState(id ? true : false);
  const [tradeCountryState, settradeCountryState] = useState(null);
  const [additionalDocFiles, setAdditionalDocFiles] = useState([]);
  const [additionalDocumentArray, setAdditionalDocumentArray] = useState([]);
  const [tradingDocFiles, setTradingDocFiles] = useState([]);
  const [tradingDocumentArray, setTradingDocumentArray] = useState([]);
  const [passportDocFiles, setPassportDocFiles] = useState([]);
  const [passportDocumentArray, setPassportDocumentArray] = useState([]);
  const [tradeccode, settradeccode] = useState(null);
  const [nationalityState, setNationalityState] = useState(null);
  const [nationalityCode, setNationalityCode] = useState(null);
  const [nationalityList, setNationalityList] = useState([]);
  const [nationalityLoading, setNationalityLoading] = useState(false);
  const [phoneErrors, setPhoneErrors] = useState(false);
  const [countryListLoading, setCountryListLoading] = useState(false);
  const [alternateMobilePhoneNumber, setAlternateMobilePhoneNumber] =
    useState();
  const [mobilePhoneNumber, setMobilePhoneNumber] = useState();
  const [openAddDoc, setOpenAddDoc] = useState(false);
  const [passportDocumentError, setPassportDocumentError] = useState({
    error: false,
    message: "",
  });
  const [tradingDocumentError, seTtradingDocumentError] = useState({
    error: false,
    message: "",
  });
  const [additionalDocumentError, setAdditionalDocumentError] = useState({
    error: false,
    message: "",
  });
  const [addDocValidationOn, setAddDocValidationOn] = useState(false);
  const [
    alternateMobilePhoneNumberCountryCode,
    setAlternateMobilePhoneNumberCountryCode,
  ] = useState();
  const [countryCode, setCountryCode] = useState();
  const [customerData, setCustomerData] = useState();
  const portal_booking_id = localStorage.getItem("portalBookingId");
  const formStatus = sessionStorage.getItem("formStatus") || null;
  const editPath = localStorage.getItem("editPath") || false;
  const booking_page = localStorage.getItem("booking_page") || null;
  const translate = useTranslate();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    watch,
    control
  } = useForm();

  useEffect(() => {
    if(!formStatus) {
      navigate("/home");
    }
    
    if(formStatus && formStatus !== "/booking-reservation-form") {
      navigate(formStatus, { state: { units, project_id } });
    }

    country(setCountryList, setCountryListLoading, notification);
    nationality(setNationalityList, setNationalityLoading, notification);
    id &&
      getLeadById(
        setContactLoading,
        id,
        setFormData,
        notification,
        "company",
        addDocs,
        portal_booking_id,
        addPhoneNumbers
      );

    document.body.classList.add("profile-page");
    document.body.classList.add("change-pass-page");
    document.body.classList.add("reservation-form");
    return () => {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("change-pass-page");
      document.body.classList.remove("reservation-form");
    };
  }, []);

  useEffect(() => {
    if (formData?.trade_license_country) {
      const country = countryList?.find(
        (country) => country.country_code == formData?.trade_license_country
      );
      settradeCountryState(country);
      settradeccode(formData?.trade_license_country);
    }
  }, [formData?.trade_license_country, countryList]);

  const changeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const setDocumentError = () => {
    if (passportDocFiles?.length === 0) {
      setError("passportDocFiles", {
        type: "required",
        message: translate("ra.validation.uploadCompanyPassport")
      })
      setPassportDocumentError({
        error: true,
        message: translate("ra.validation.uploadCompanyPassport"),
      });
    }
    if (openAddDoc && additionalDocFiles?.length === 0) {
      setError("additionalDocFiles", {
        type: "required",
        message: translate("ra.validation.validCompanyID")
      })
      setAdditionalDocumentError({
        error: true,
        message: translate("ra.validation.validCompanyID"),
      });
    }
    if (tradingDocFiles?.length === 0) {
      setError("tradeDocFiles", {
        type: "required",
        message: translate("ra.validation.valuidTradeLicense"),
      })
      seTtradingDocumentError({
        error: true,
        message: translate("ra.validation.valuidTradeLicense"),
      });
    }
  }

  useEffect(() => {
    passportDocFiles?.length > 0 && setPassportDocumentError({ error: false, message: null });
    passportDocFiles?.length === 0 && passportDocumentError?.message === null && setDocumentError();
    additionalDocFiles?.length > 0 && openAddDoc && setAdditionalDocumentError({ error: false, message: null });
    additionalDocFiles?.length === 0 && openAddDoc && additionalDocumentError?.message === null && setDocumentError();
    tradingDocFiles?.length > 0 && seTtradingDocumentError({ error: false, message: null });
    tradingDocFiles?.length === 0 && tradingDocumentError?.message === null && setDocumentError();
  }, [additionalDocFiles, passportDocFiles, tradingDocFiles])

  const addDocs = (resData) => {
    const progressArray = [...progress] || [];
    progressArray[0] = "100";
    setProgress(progressArray);
    resData?.id_number && setOpenAddDoc(true);
    const arrPassport = [];
    const arrTrading = [];
    const arrAdditional = [];

    resData?.documents?.map((doc) => {
      if (doc.doc_type === "passport") {
        arrPassport.push(doc);
        setPassportDocFiles(arrPassport);
      }
      if (doc.doc_type === "additional") {
        arrAdditional.push(doc);
        setOpenAddDoc(true);
        setAdditionalDocFiles(arrAdditional);
      }
      if (doc?.doc_type === "trading_license") {
        arrTrading.push(doc);
        setTradingDocFiles(arrTrading);
      }
    });
  };

  const addDocuments = (formData, data) => {
    if (passportDocFiles?.length) {
      passportDocFiles?.map((file) => file?.name && formData.append("file", file));
    }
    if (additionalDocFiles?.length) {
      additionalDocFiles?.map((file) => file?.name && formData?.append("file", file));
    }
    if (tradingDocFiles?.length) {
      tradingDocFiles?.map((file) => file?.name && formData?.append("file", file));
    }
    if (
      additionalDocumentArray?.length ||
      passportDocumentArray?.length ||
      tradingDocumentArray?.length
    ) {
      let docArray = [
        ...additionalDocumentArray,
        ...passportDocumentArray,
        ...tradingDocumentArray,
      ];
      formData?.append("docData", JSON.stringify(docArray));
    }

    deleteId?.length && formData?.append("deletedDocIds", JSON.stringify([...deleteId]));
  };

  const addPhoneNumbers = (resData) => {
    setMobilePhoneNumber(resData?.authorised_mobile_no || resData?.agent_mobile_no);
    setCountryCode(resData?.authorised_mobile_no_country_code || resData?.agent_mobile_no_country_code);
    setAlternateMobilePhoneNumber(resData?.authorised_alt_mobile_no);
    setAlternateMobilePhoneNumberCountryCode(
      resData?.authorised_alt_mobile_no_country_code
    );
  }

  const addPayload = (formData, data) => {
    const {
      address_line_1,
      address_line_2,
      city,
      company_name,
      email,
      first_name,
      last_name,
      trade_license_expiry_date,
      postal_code_po_box,
      trade_license_number,
    } = data;

    const unit_object = units?.map((unit) => {
      return {
        unit_id: unit?.officE_SECTION_ID,
        unit_no: unit?.officE_SECTION_CODE,
        total_unit_price: unit?.propertY_PRICE,
        saleable_area_sqft: unit?.saleablE_AREA_SQFT,
        floor_parcel_id: unit?.flooR_PARCEL_ID,
        release_id: unit?.releasE_ID,
        building_land_id: unit?.buildinG_LAND_ID || "",
      };
    });

    const payload = {
      company_name,
      company_address_line_1: address_line_1,
      city,
      country: ccode,
      trade_license_number,
      trade_license_expiry_date: getDateFormat(trade_license_expiry_date),
      trade_license_country: tradeccode,
      authorised_first_name: first_name,
      authorised_last_name: last_name,
      authorised_nationality: nationalityCode,
      authorised_email: email,
      authorised_mobile_no_country_code: countryCode?.toString(),
      authorised_mobile_no: mobilePhoneNumber,
      project_id: project_id,
      unit_data: unit_object,
      org_id: units[0]?.organizatioN_ID,
      portal_booking_id,
      postal_code_po_box: postal_code_po_box,
      company_address_line_2: address_line_2 || "",
      authorised_alt_mobile_no_country_code: alternateMobilePhoneNumber ? alternateMobilePhoneNumberCountryCode?.toString() : "",
      authorised_alt_mobile_no: alternateMobilePhoneNumber || ""
    };

    if (id) payload.id = id;

    formData.append("payload", JSON.stringify(payload));
  };

  const onSubmit = (data) => {
    if (!passportDocumentError?.error && !additionalDocumentError?.error && !tradingDocumentError?.error) {
      let formData = new FormData();
      let headerPayload = {
        project_id,
        unit_ids: units?.map((unit) => unit?.officE_SECTION_ID).join(","),
        portal_booking_id: portal_booking_id
      }
      addPayload(formData, data);
      addDocuments(formData, data);
      customerCompanyService(
        setCustomerCompanyLoading,
        formData,
        notification,
        setCustomerData,
        customerData,
        navigate,
        units,
        project_id,
        translate,
        JSON.stringify(headerPayload),
        bookingFailedPopup,
        setBookingFailedPopup,
      );
    }
  };

  useEffect(() => {
    setTabName("1", "", "", watch("company_name"));
  }, [watch("company_name")]);

  useEffect(() => {
    let errorArray = [...errorVal];
    if (Object.keys(errors)?.length > 0) {
      errorArray[0] = true;
    } else {
      errorArray[0] = false;
    }
    setErrorVal(errorArray);
  }, [Object.keys(errors)?.length]);

  const removeAdditional = () => {
    if (openAddDoc) {
      setDeleteId((additionalDocFiles?.map(doc => doc?.id)));
      setOpenAddDoc(false);
      setAdditionalDocumentError({ error: false, message: "" });
      setAdditionalDocFiles([]);
      setAdditionalDocumentArray([]);
      clearErrors("additionalDocFiles");
    }
  }

  let newObj = {
    ...watch(),
    ccode,
    nationalityCode,
    trade_license_country: tradeccode,
    tradeLicenceDocument: tradingDocFiles?.length > 0 ? 1 : "",
    passportDocFiles: passportDocFiles?.length > 0 ? 1 : "",
    mobilePhoneNumber,
    alternateMobilePhoneNumber,
  };
  delete newObj.nationality;
  delete newObj.country;

  if (openAddDoc && additionalDocFiles?.length > 0) {
    newObj.additionalDocFiles = 1;
  }

  const reduseDta = Object.keys(newObj).reduce(
    (a, b) => {
      if (
        (b === "address_line_2" || b === "alternateMobilePhoneNumber") &&
        newObj[b]
      ) {
        a.invalid++;
      } else if (newObj[b]) {
        a.totalLength++;
      }
      a.total = a.totalLength + a.invalid;
      return a;
    },
    { invalid: 0, totalLength: 0, total: 0 }
  );

  useEffect(() => {
    let total = openAddDoc ? 16 : 15;
    total += reduseDta.invalid;
    const percentage = (reduseDta.total / total) * 100;
    const progressArray = [...progress] || [];
    progressArray[0] = percentage;
    setProgress(progressArray);
  }, [reduseDta]);

  useEffect(() => {
    if (tabs?.length && tabs[0]?.status) {
      if (tabs[0]?.leadType === "Customer") {
        notification("ra.notification.autoFilledCustomer", { type: "info" });
      } else {
        notification("ra.notification.autoFilledLead", { type: "info" });
      }
      tabs[0].status = false;
      localStorage.setItem("tabs", JSON.stringify(tabs));
    }
  }, [])

  return (
    <>
      {(nationalityLoading && countryListLoading || customerCompanyLoading || contactLoading) && <Loader cls="form-loader-color" />}
      {!nationalityLoading && !countryListLoading && !customerCompanyLoading && !contactLoading && (
        <>
          <Grid className="booking-reservation">
            <h3>{translate("ra.fieldName.companyDetails")}</h3>
            <Grid className="booking-reservation-info">
              {/* Company name */}
              <Grid>
                <InputField
                  id="outlined-basic-company_name"
                  name={translate("ra.fieldName.companyName")}
                  defaultValue={formData?.company_name}
                  register={register}
                  errors={errors}
                  state="company_name"
                  onChange={changeHandler}
                  message={translate("ra.validation.validCompanyName")}
                  pattern={{
                    value: companyNamePattern,
                    message: translate("ra.validation.mandatoryField"),
                  }}
                  placeholderHide={true}
                />
              </Grid>
              <ResidentialDetails
                register={register}
                errors={errors}
                changeHandler={changeHandler}
                formData={formData}
                clearErrors={clearErrors}
                countryState={countryState}
                setCountryState={setCountryState}
                setccode={setccode}
                countryList={countryList}
                customerType={customerType}
                watch={watch}
              />

              {/* Trade License Number */}
              <Grid container spacing={0}>
                <InputField
                  name={translate("ra.fieldName.tradeNo")}
                  register={register}
                  errors={errors}
                  defaultValue={formData?.trade_license_number || ""}
                  id="outlined-basic-trade_license_number"
                  state="trade_license_number"
                  sx={{ width: "100%", marginBottom: "20px" }}
                  message={translate("ra.validation.validTradeNo")}
                  placeholderHide={true}
                  onChange={changeHandler}
                />
              </Grid>

              {/* trade_license_expiry_date */}
              <Grid container spacing={0} className="date-calender-icon">
                <DatePickerComponent
                  title={translate("ra.fieldName.tradeExpiryDate")}
                  control={control}
                  name="trade_license_expiry_date"
                  value={formData?.trade_license_expiry_date}
                  message={translate("ra.validation.validtradeLicencedate")}
                  minDate={expiryDate}
                  errors={errors?.trade_license_expiry_date}
                  onChangeHandler={(date) => {
                    setFormData({ ...formData, ["TradeLicenceDate"]: getDateFormat(date) })
                  }}
                />
                <i className="iconwasalt icon-calendar-month date-calender-position"/>
              </Grid>

              {/* Trade Licence country */}
              <Grid container className="new-select-class" spacing={0}>
                <AutoCompleteComponent
                  title={translate("ra.fieldName.tradeCountry")}
                  value={tradeCountryState}
                  onChange={(e, newInputValue) => {
                    settradeCountryState(newInputValue);
                    settradeccode(newInputValue?.country_code);
                    clearErrors("trade_license_country");
                    setFormData({ ...formData, ["TradeCountry"]: newInputValue?.country_code })
                  }}
                  options={countryList}
                  getOptionLabel={(option) => option?.country_name || ""}
                  name="trade_license_country"
                  placeholderHide={true}
                  errors={errors?.trade_license_country}
                  register={register}
                  message={translate("ra.validation.validTradeCountry")}

                />
              </Grid>

              {/* Attach Trading License */}
              <Grid className="upload-section" container spacing={0}>
                <Typography variant="subtitle1">
                  {translate("ra.fieldName.attachTradeLicence")}
                  <span style={{ color: "#EF4349" }}>*</span>
                </Typography>
                <MultipleDocumentUpload
                  notification={notification}
                  deleteId={deleteId}
                  setDeleteId={setDeleteId}
                  maxFile={1}
                  fileType="trading_license"
                  documentFilesArray={tradingDocFiles}
                  setDocumentFilesArray={setTradingDocFiles}
                  documentObjectArray={tradingDocumentArray}
                  setDocumentObjectArray={setTradingDocumentArray}
                  error={tradingDocumentError?.error}
                  message={tradingDocumentError?.message}
                  clearErrors={clearErrors}
                />
              </Grid>

              {/*--------- Additional Document  Details----------- */}
              <Grid className="additional-details">
                <h3>{translate("ra.fieldName.additionalDocDetails")}</h3>
                {!openAddDoc && (
                  <Button className="add-remove-id-button"
                    onClick={() => {
                      !openAddDoc
                        ? (setOpenAddDoc(true), setAddDocValidationOn(true))
                        : (setOpenAddDoc(false), setAddDocValidationOn(false));
                    }}
                  >
                    <i className="iconwasalt icon-plus"></i> <span>{translate("ra.fieldName.addAdditionalDoc")}</span>
                  </Button>
                )}
                {openAddDoc ? (
                  <>
                    {/*id upload doc*/}
                    <Grid className="upload-section" container spacing={0}>
                      <Typography variant="subtitle1">
                        {translate("ra.fieldName.additionalUploadComLabel")}
                        <span style={{ color: "#EF4349" }}>*</span>
                      </Typography>
                      <MultipleDocumentUpload
                        notification={notification}
                        deleteId={deleteId}
                        setDeleteId={setDeleteId}
                        maxFile={10}
                        fileType="additional"
                        documentFilesArray={additionalDocFiles}
                        setDocumentFilesArray={setAdditionalDocFiles}
                        documentObjectArray={additionalDocumentArray}
                        setDocumentObjectArray={setAdditionalDocumentArray}
                        error={additionalDocumentError?.error}
                        message={additionalDocumentError?.message}
                        clearErrors={clearErrors}
                      />
                    </Grid>

                    <Button className="add-remove-id-button"
                      onClick={() => removeAdditional()}
                    >
                      <i className="iconwasalt icon-close"></i> <span>{translate("ra.fieldName.removeAdditionalDoc")} </span>
                    </Button>
                  </>
                ) : null}
              </Grid>
            </Grid>

            {/* Company Details */}
            <Grid className="booking-reservation-info booking-reservation-info-last">
              <h3>{translate("ra.fieldName.authorisedPersonsDetails")}</h3>
              <Typography component="div" className="authorised-details" >
                <BasicDetailsForm
                  register={register}
                  formData={formData}
                  errors={errors}
                  setNationalityCode={setNationalityCode}
                  setNationalityState={setNationalityState}
                  nationalityState={nationalityState}
                  clearErrors={clearErrors}
                  nationalityList={nationalityList}
                  customerType={customerType}
                  setPhoneErrors={setPhoneErrors}
                  setError={setError}
                  mobilePhoneNumber={mobilePhoneNumber}
                  phoneErrors={phoneErrors}
                  alternateMobilePhoneNumber={alternateMobilePhoneNumber}
                  setMobilePhoneNumber={setMobilePhoneNumber}
                  setCountryCode={setCountryCode}
                  setAlternateMobilePhoneNumber={setAlternateMobilePhoneNumber}
                  setAlternateMobilePhoneNumberCountryCode={setAlternateMobilePhoneNumberCountryCode}
                />
              </Typography>
              {/* Passport doc*/}
              <Grid className="upload-section">
                <Typography variant="subtitle1">
                  {translate("ra.fieldName.attachPassportShareholder")}
                  <span style={{ color: "#EF4349" }}>*</span>
                </Typography>
                <MultipleDocumentUpload
                  notification={notification}
                  deleteId={deleteId}
                  setDeleteId={setDeleteId}
                  maxFile={4}
                  fileType="passport"
                  documentFilesArray={passportDocFiles}
                  setDocumentFilesArray={setPassportDocFiles}
                  documentObjectArray={passportDocumentArray}
                  setDocumentObjectArray={setPassportDocumentArray}
                  error={passportDocumentError?.error}
                  message={passportDocumentError?.message}
                  clearErrors={clearErrors}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container className="company-details-button align-right" spacing={0}>
            {!booking_page &&
              <Button
                variant="outlined"
                className="mobile-only"
                onClick={() => onClickBack()}
              >
                {translate("ra.button.back")}
              </Button>
            }
            <Button
              variant="contained"
              className={booking_page ? "full-width-button" : ""}
              onClick={() => {
                handleSubmit(onSubmit)();
                setDocumentError();
                setPhoneErrors(id ? false : true);
                Object.keys(errors)?.length > 0 && 
                  notification("ra.notification.invalidDetails", { type: "error" });
              }}
            >
              {translate("ra.button.continue")}
            </Button>
          </Grid>
        </>
      )}
    </>
  )
};

export default CompanyCustomerForm;