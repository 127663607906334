import React, { useEffect } from "react";
import { useTranslate } from "react-admin";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Grid,
  Container,
  Button,
  Typography
} from "@mui/material"
import Header from "Components/Header";
import { removeLocalStorage } from "Utils/removeStorage";

const ReservationFormSubmitted = ({ notification, userLoading, userData }) => {
  let formStatus = sessionStorage.getItem("formStatus") || null;
  const bookingState = JSON.parse(localStorage.getItem("bookingState")) || null;
  const location = useLocation();
  const navigate = useNavigate();
  const state = location?.state || bookingState;
  const translate = useTranslate();

  useEffect(() => {
    if(!formStatus) {
      navigate("/home");
    }

    if(formStatus && formStatus !== "/reservation-form-submitted") {
      navigate(formStatus, { state });
    }

    document.body.classList.add(
      'profile-page'
    );
    return () => {
      document.body.classList.remove(
        'profile-page'
      );
    };
  }, []);

  const onClickContinue = () => {
    navigate("/token-payment-details", { state });
    sessionStorage.setItem("formStatus", "/token-payment-details");
  }

  const redirectPath = () => {
    removeLocalStorage();
    localStorage.removeItem("page");
    localStorage.removeItem("params");
    localStorage.removeItem("page-redirect");
    navigate("/my-bookings");
  }

  return (
    <>
      <Header
        path="My Bookings"
        picture={userData?.profile}
        pictureLoading={userLoading}
        notification={notification} 
      />
      <Container className="company-container new-fix-container lead-panel-section invoice-submitted-panel reservation-form-submitted mt-0">
        <Container className="company-details">
          <Grid className="form-panel profile-form-panel add-team-panel invoice-submitted" sx={{ width: "100%" }}>
            <Typography className="icon" ><i className="iconwasalt icon-check" /></Typography>
            <Typography variant="h2">{translate("ra.pageTitle.ReservationFormSubmitted")}</Typography>
            <Typography variant="subtitle1">{translate("ra.content.reservationFormSubmitted")}</Typography>

            <Grid container spacing={0} className="invoice-success-button">
              <Button
                variant="outlined"
                className=""
                onClick={redirectPath}
              >
                {translate("ra.button.gotoBookings")}
              </Button>
              <Button
                variant="contained"
                className="new-color-button invoice-success-button"
                onClick={() => onClickContinue()}
              >
                {translate("ra.button.continue")}
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </>
  )
}

export default ReservationFormSubmitted