import React from "react";
import { Navigate, useLocation } from "react-router-dom";

const ProtectedRoute = ({ children, oracle_status }) => {
    const token = localStorage.getItem("token");
    const user_type = JSON.parse(localStorage.getItem("user_type"));
    const location = useLocation();
    const url = new URL(window.location);
    const parse = url?.searchParams;
    const requestId = parse.get("randomId");

    if(requestId) {
        window.localStorage.clear();
        <Navigate to="/reset-password" />
    }

    if (!token) {
        return <Navigate to="/login" />
    }

    // blocking url change based on oracle status
    if (token && oracle_status) {
        if (oracle_status?.toLowerCase() === "active" && ((location?.pathname?.includes("/register-myself")) || (location?.pathname?.includes("/register-agency")))) {
            return <Navigate to="/home" />
        }

        if (oracle_status?.toLowerCase() === "inprocess" && !location?.pathname?.includes("/upload")) {
            if (user_type === 1) {
                return <Navigate to="/register-myself/upload" />
            }
            if (user_type === 2) {
                return <Navigate to="/register-agency/upload" />
            }
        }

        if (oracle_status?.toLowerCase() !== "active") {
            if (user_type === 1 && !location?.pathname?.includes("/register-myself")) {
                return <Navigate to="/register-myself/personal" />
            }
            if (user_type === 2 && !location?.pathname?.includes("/register-agency")) {
                return <Navigate to="/register-agency/personal" />
            }
        }
    }

    return children

};

export default ProtectedRoute;