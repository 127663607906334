import { apiServiceRequest } from "Utils/axiosInstance";
import { bookingContactFromQuery } from "Utils/filterData";
import { getFilterQuery } from "Utils/queryValues";
import { getUserToken } from "Utils/tokenProvider";

export const getLeadList = async (setLoading, data, setData, setTotalCount, setCount, page, notify, queryData, setFilter, salesList, loadType, pageName) => {
    if (setLoading) setLoading(true);

    let queryParam = {};
    if (queryData) {
        queryParam = getFilterQuery(queryData);
    }

    const customerType = localStorage.getItem("customerType") || "";

    const res = await apiServiceRequest({
        url: `/all-leads-customers?limit=20&page=${page ? page : 0}${(customerType && pageName) ? `&user_type=${customerType}` : ""}${queryParam ? queryParam : ""}`,
        headers: { Authorization: `Bearer ${await getUserToken(notify)}` },
        notify: notify,
    });

    const resData = res?.data?.list?.map((list) => {
        const first_name = list?.first_name || list?.authorised_first_name || list?.agent_first_name;
        const last_name = list?.last_name || list?.authorised_last_name || list?.agent_last_name;
        const country_code = list?.mobile_no_country_code || list?.authorised_mobile_no_country_code || list?.agent_mobile_no_country_code;
        const mobile_no = list?.mobile_no || list?.authorised_mobile_no || list?.agent_mobile_no;

        return {
            id: list?.id,
            name: list?.lead_type === 1 ? `${first_name} ${last_name}` : list?.company_name,
            first: list?.first_name || list?.authorised_first_name || list?.agent_first_name,
            last: list?.last_name || list?.authorised_last_name || list?.agent_last_name,
            mobile: `+(${country_code || ""}) ${mobile_no || ""}`,
            status: list?.is_customer === 1 ? "Customer" : "Prospect",
            leadType: list?.lead_type === 1 ? "Individual" : "Company",
            salesperson: list?.saleperson_first_name ? `${list?.saleperson_first_name || ""} ${list?.saleperson_last_name || ""}` : "-",
            company_name: list?.company_name
        }
    }) || [];


    if (loadType) {
        const additionalData = [...data, ...resData];
        setData(additionalData);
    } else {
        setData(resData);
    }

    setTotalCount(res?.data?.total_count || 0);
    setCount(res?.data?.count || 0);
    if (setFilter) bookingContactFromQuery(queryData, salesList, setFilter);
    if (setLoading) setLoading(false);
};

export const getLeadById = async (setLoading, id, setData, notify, type, addDocs, portal_booking_id, addPhoneNumbers, isResidence, setResidenceValues ) => {
    setLoading(true);

    const res = await apiServiceRequest({
        url: `view-lead-customer/${id}${type ? `?portalBookingId=${portal_booking_id}` : ""}`,
        headers: { Authorization: `Bearer ${await getUserToken(notify)}` },
        notify: notify
    });

    if (res?.code === "success") {
        const resData = {
            company_name: res?.data?.company_name,
            trade_license_number: res?.data?.trade_license_number,
            first_name: res?.data?.first_name || res?.data?.authorised_first_name || res?.data?.agent_first_name,
            last_name: res?.data?.last_name || res?.data?.authorised_last_name || res?.data?.agent_last_name,
            mobile_no_country_code: res?.data?.mobile_no_country_code || res?.data?.authorised_mobile_no_country_code || res?.data?.agent_mobile_no_country_code,
            mobile_no: res?.data?.mobile_no || res?.data?.authorised_mobile_no || res?.data?.agent_mobile_no,
            email: res?.data?.email || res?.data?.authorised_email || res?.data?.agent_email,
            passport_number: res?.data?.passport_number,
            place_id_issue: res?.data?.place_id_issue,
            salesperson_id: res?.data?.salesperson_id,
            salesperson_name: res?.data?.salesperson_first_name ? (res?.data?.salesperson_first_name || "").concat(" ", res?.data?.salesperson_last_name || "") : "-",
            leadType: res?.data?.lead_type === 1 ? "Individual" : "Company",
            leadStatus: res?.data?.is_customer === 1 ? "Customer" : "Prospect",
            mobile_no_with_code:res?.data?.mobile_no_with_code,
            dd_mobile_no:res?.data?.dd_mobile_no,
            agent_mobile_no_with_code:res?.data?.agent_mobile_no_with_code,
            dd_agent_mobile_no:res?.data?.dd_agent_mobile_no
        }
       if(type) {
        setData && setData(res?.data);
        isResidence && setResidenceValues(res?.data);
        addPhoneNumbers && addPhoneNumbers(res?.data);
       } else{
        setData(resData);
       } 
       res?.data?.documents?.length && addDocs && addDocs(res?.data);
    }
    setLoading(false);
};

export const addUpdateLead = async (setLoading, id, salesPersonValue, data, notify, navigate, leadType) => {
    setLoading(true);
    const msgShow = id ? "ra.notification.prospectUpdate" : "ra.notification.prospectCreate";
    
    let payload;
    if (leadType) {
        payload = {
            first_name: data?.first_name?.trim(),
            last_name: data?.last_name?.trim(),
            mobile_no_country_code: data?.mobile_no_country_code,
            mobile_no: data?.mobile_no,
            email: data?.email?.trim(),
            passport_number: data?.passport_number?.trim() || "",
            place_id_issue: data?.place_id_issue?.trim() || "",
            salesperson_id: salesPersonValue?.user_id || "",
            mobile_no_with_code:data?.mobile_no_with_code,
            dd_mobile_no:data?.dd_mobile_no
        }
    } else {
        payload = {
            company_name: data?.company_name?.trim(),
            trade_license_number: data?.trade_license_number?.trim(),
            agent_first_name: data?.first_name?.trim(),
            agent_last_name: data?.last_name?.trim(),
            agent_mobile_no_country_code: data?.mobile_no_country_code,
            agent_mobile_no: data?.mobile_no,
            agent_email: data?.email?.trim(),
            salesperson_id: salesPersonValue?.user_id || "",
            agent_mobile_no_with_code:data?.agent_mobile_no_with_code,
            dd_agent_mobile_no:data?.dd_agent_mobile_no

        }
    }

    
    if (id) {
        payload.id = id;
    }

    const res = await apiServiceRequest({
        url: leadType ? "/individual-lead" : "/company-lead",
        method: "post",
        data: payload,
        headers: { Authorization: `Bearer ${await getUserToken(notify)}` },
        notify: notify
    });

    if (res?.code === "success") {
        notify(msgShow, { type: "success" });
        if (navigate) {
            if (id) {
                navigate(-1);
            } else {
                navigate("/contacts", { replace: true });
            }
        }
    } else {
        if (res?.code === "failed" && res?.message) notify(res?.message, { type: "error" });
    }
    setLoading(false);
};

export const deleteLead = async (setLoading, id, notify, setDeleteSuccess, setDeletePopup, setConfirmationPopup) => {
    setLoading(true);

    const res = await apiServiceRequest({
        url: `/remove-lead-customer/${id}`,
        method: "delete",
        headers: { Authorization: `Bearer ${await getUserToken(notify)}` },
        notify: notify
    });

    if (res?.code === "success") {
        setDeleteSuccess(true);
        setDeletePopup(false);
        setConfirmationPopup && setConfirmationPopup(true);
    }
    setLoading(false);
};

export const getSalesperson = async (setLoading, setData, notify, type) => {
    if (setLoading) setLoading(true);
    const res = await apiServiceRequest({
        url: "/all-salesperson",
        headers: { Authorization: `Bearer ${await getUserToken(notify)}` },
        notify: notify
    });

    let resData = [];
    if (res?.code === "success") {
        resData = res?.data?.length > 0 ? res?.data : [];
        if (type !== "form") {
            resData?.unshift({
                id: 1,
                user_id: "N/A",
                name: "N/A"
            })
        }
        setData(resData);
    }
    if (setLoading) setLoading(false);
    return resData;
};
