import React, { useState, useEffect } from "react";
import { useRedirect, useTranslate, } from "react-admin";
import { useLocation } from "react-router-dom";
import {
  Grid,
  Container,
  Button,
  Typography
} from "@mui/material";
import Header from "Components/Header";

const InvoiceSuccess = ({ notification, userLoading, userData }) => {
  const redirect = useRedirect();
  const location = useLocation();
  const translate = useTranslate();
  const unit_no = location?.state?.unit_no;

  useEffect(() => {
    if(!unit_no) {
      redirect("/home");
    }
    document.body.classList.add(
      "profile-page"
    );
    return () => {
      document.body.classList.remove(
        "profile-page"
      );
    };
  }, [])

  const redirectPage = () => {
    redirect("/my-bookings");
  }

  return (
    <>
      <Header 
        path="My Bookings" 
        picture={userData?.profile} 
        pictureLoading={userLoading} 
        notification={notification} 
        userData={userData} 
      />
      <Container className="company-container new-fix-container lead-panel-section invoice-submitted-panel mt-0">
        <Container className="company-details">
          <Grid className="form-panel profile-form-panel add-team-panel invoice-submitted" sx={{ width: "100%" }}>
            <Typography className="close-icon mobile-only" >
              <i className="iconwasalt icon-close" />
            </Typography>
            <Typography className="icon" >
              <i className="iconwasalt icon-check" />
            </Typography>
            <Typography variant="h2">
              {translate("ra.fieldName.invoiceSubmit")}
            </Typography>
            <Typography variant="subtitle1">
              {translate("ra.fieldName.invoiceRequest", { unit: unit_no })}
            </Typography>

            <Grid container spacing={0} className="invoice-success-button">
              <Button
                variant="contained"
                className="new-color-button invoice-success-button"
                onClick={redirectPage}
              >
                {translate("ra.button.bookings")}
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </>
  );
};

export default InvoiceSuccess;