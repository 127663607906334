import { setUserToken } from "./tokenProvider";
import loginService from "Services/loginService";

const authProvider = {
  login: async ({ email, password, rememberMe, setOracle_status, notify }) => {
    let res = loginService(
      { email, password, remember_me: rememberMe },
      notify
    );
    return res
      .then((response) => {
        if (response.code !== "success") {
          throw new Error(response?.message);
        }
        return response;
      })
      .then((response) => {
        const user_type = response?.details?.user_type;
        let oracle_status = response?.details?.oracle_status || "-";
        // Modify the value if oracle_status is "inprocess" to sent_to_oracle
        if (oracle_status?.toLowerCase() === "inprocess") {
          oracle_status = "sent_to_oracle";
        }
        const user_id = response?.details?.user_id || response?.details?.userId;
        setUserToken(response?.token, response?.refresh_token);
        localStorage.setItem("user_type", JSON.stringify(user_type));
        localStorage.setItem("user_id", JSON.stringify(user_id));
        localStorage.setItem("isLogin", true);

        // Setting oracle status in app.js for routing
        setOracle_status(oracle_status);
        let redirectTo = "";

        if (oracle_status?.toLowerCase() === "active") {
          redirectTo = "/home";
        } else if (oracle_status?.toLowerCase() === "inprocess") {
          if (user_type === 1) {
            redirectTo = "/register-myself/upload";
          }
          if (user_type === 2) {
            redirectTo = "/register-agency/upload";
          }
        } else if (user_type === 1) {
          redirectTo = "/register-myself/personal";
        } else if (user_type === 2) {
          redirectTo = "/register-agency/personal";
        }
        return Promise.resolve({ redirectTo });
      })
      .catch((response) => {
        throw new Error(response?.message);
      });
  },
  logout: () => {
    localStorage.clear();
    return Promise.resolve();
  },
  checkAuth: () => {
    if (localStorage.getItem("token")) return Promise.resolve();
    else {
      return Promise.reject({ message: false });
    }
  },
  getPermissions: () => {
    // Required for the authentication to work
    return Promise.resolve();
  },
  // ...
};

export default authProvider;
