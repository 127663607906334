import React from "react";
import { Navigate, useLocation } from "react-router-dom";

const PublicRoute = ({ children, oracle_status }) => {
    const token = localStorage.getItem("token");
    const user_type = JSON.parse(localStorage.getItem("user_type"));
    const url = new URL(window.location);
    const parse = url?.searchParams;
    const requestId = parse.get("randomId");

    if(requestId) {
        window.localStorage.clear();
        <Navigate to="/reset-password" />
    }

    if (token) {
        if (oracle_status?.toLowerCase() === "active") {
            return <Navigate to="/home" />
        }
        if (user_type === 1) {
            return <Navigate to="/register-myself/personal" />
        }
        if (user_type === 2) {
            return <Navigate to="/register-agency/personal" />
        }
    }
   
    return children

};

export default PublicRoute; 