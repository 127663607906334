import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, ClickAwayListener, Tooltip } from "@material-ui/core";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useTranslate, useStoreContext } from "react-admin";
import { Grid, Typography } from "@mui/material";
import PdfPic from "images/pdf-file.svg";
import { getLeadById } from "Services/leadService";
import nationality from "Services/nationalityService";
import country from "Services/countryService";
import DeleteCustomerPopup from "../../DeleteCustomerPopup";
import DialogBox from "Components/DialogBox";
import Loader from "Components/Loader";
import {
  customerDelete,
  customerReviewSubmit,
} from "Services/customerCreationandUpdation";
import { blockUnits } from "Services/createBookingId";

const ReviewIndividualCustomer = ({
  notification,
  id,
  customerType,
  panelValue,
  tabs,
  setSelectedTab,
  onClickBack,
  bookingFailedPopup,
  setBookingFailedPopup,
  dialogboxcss,
}) => {
  const [loading, setLoading] = useState(false);
  const [nationalityLoading, setNationalityLoading] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [nationalityList, setNationalityList] = useState([]);
  const [countryListLoading, setCountryListLoading] = useState();
  const [contactLoading, setContactLoading] = useState(true);
  const [formData, setFormData] = useState();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const [nationalityValue, setNationalityValue] = useState();
  const [countryValue, setCountryValue] = useState();
  const [dialogImage, setDialogImage] = useState();
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const portalBookingId = localStorage.getItem("portalBookingId");
  const [openTooltip, setOpenTooltip] = useState(false);
  const [bookingReleaseLoading, setBookingReleaseLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const translate = useTranslate();
  const store = useStoreContext();
  const units = location?.state?.units;
  const project_id = location?.state?.project_id;
  const [uids, setUids] = useState([]);

  let headerPayload = {
    project_id,
    unit_ids: units?.map((unit) => unit?.officE_SECTION_ID).join(","),
    portal_booking_id: portalBookingId,
  };

  useEffect(() => {
    nationality(setNationalityList, setNationalityLoading, notification);
    country(setCountryList, setCountryListLoading, notification);
    if (id) {
      getLeadById(
        setContactLoading,
        id,
        setFormData,
        notification,
        "individual",
        "",
        portalBookingId
      );
    } else {
      setContactLoading(false);
    }
    document.body.classList.add(
      "profile-page",
      "change-pass-page",
      "reservation-form"
    );
    return () => {
      document.body.classList.remove(
        "profile-page",
        "change-pass-page",
        "reservation-form"
      );
    };
  }, []);

  const editCustomer = () => {
    sessionStorage?.setItem("formStatus", "/booking-reservation-form");
    localStorage.setItem("editPath", true);
    navigate("/booking-reservation-form", { state: { units, project_id } });
  };

  const callPostDelete = () => {
    setConfirmationPopup(false);
    let tabs = JSON.parse(localStorage?.getItem("tabs"));
    if (tabs?.length > 1) {
      tabs = tabs?.filter((tab) => tab?.id !== id);
      tabs?.map((tab, index) => {
        tab.value = index + 1;
      });
      localStorage.setItem("tabs", JSON.stringify([...tabs]));

      if (tabs?.length > 1) {
        sessionStorage.setItem("formStatus", "/assign-ownership-percentage");
        navigate("/assign-ownership-percentage", {
          state: { units, project_id, tabs },
        });
      } else {
        window.location.reload();
      }
    } else {
      localStorage.removeItem("tabs");
      const apiRequestForBlock = {
        project_id: project_id,
        unit_ids: units?.map((unit) => unit?.officE_SECTION_ID)?.join(","),
        is_initial: 0,
      };
      if (portalBookingId) {
        apiRequestForBlock.portal_booking_id = parseInt(portalBookingId);
      }
      blockUnits(
        setBookingReleaseLoading,
        apiRequestForBlock,
        notification,
        "",
        "",
        "",
        "",
        "/review-units",
        navigate,
        "",
        units,
        location?.state?.from
      );
    }
  };

  const deleteCustomer = () => {
    customerDelete(
      setDeleteLoading,
      id,
      notification,
      setDeletePopup,
      setConfirmationPopup,
      JSON.stringify(headerPayload),
      bookingFailedPopup,
      setBookingFailedPopup
    );
  };

  useEffect(() => {
    store.removeItem("ownership");
    const nation = nationalityList?.find((nationality) => {
      return nationality?.code == formData?.nationality;
    })?.name;
    setNationalityValue(nation);

    const country = countryList?.find((country) => {
      return country?.country_code == formData?.country;
    })?.country_name;
    setCountryValue(country);
  }, [
    formData,
    formData?.nationality,
    formData?.country,
    nationalityList,
    countryList,
  ]);

  useEffect(() => {
    const localStorageData = localStorage.getItem("tabs");
    const dataArray = JSON.parse(localStorageData);
    if (dataArray && dataArray.length > 0) {
      const ids = dataArray.map((item) => item.id);
      setUids(ids);
    }
  }, []);

  const onClickSubmit = () => {
    if (tabs?.length == panelValue) {
      customerReviewSubmit(
        setLoading,
        notification,
        units,
        project_id,
        portalBookingId,
        JSON.stringify(headerPayload),
        navigate,
        bookingFailedPopup,
        setBookingFailedPopup
      );
    } else {
      setSelectedTab((parseInt(panelValue) + 1).toString());
    }
  };

  const onClickImage = (url, extension) => {
    if (extension === "pdf") {
      window.open(url);
    } else {
      setOpenImageDialog(true);
      setDialogImage(url);
    }
  };

  const handleTooltipOpen = () => {
    setOpenTooltip(true);
  };

  const handleTooltipClose = () => {
    setOpenTooltip(false);
  };

  return (
    <>
      {(deleteLoading ||
        countryListLoading ||
        contactLoading ||
        nationalityLoading ||
        loading ||
        bookingReleaseLoading) && <Loader cls="form-loader-color" />}
      {!countryListLoading && !contactLoading && !nationalityLoading && (
        <>
          <Grid
            className={`review-information ${dialogboxcss}`}
            sx={{ width: "100%" }}
          >
            <Grid className="booking-reservation booking-reservation-individual">
              <>
                {tabs?.length > 1 && (
                  <Grid className="booking-reservation-info">
                    <h3>{translate("ra.pageTitle.ownershipBreakdown")}</h3>
                    <Typography
                      component="div"
                      className="authorised-details ownership-breakdown"
                    >
                      {formData?.units?.map((unit) => {
                        return (
                          <Grid key={unit?.unit_id}>
                            <h6>{unit?.unit_no}</h6>
                            <p>
                              {unit?.ownership_percentage}%
                              <span>
                                {unit?.is_primary_user === 1 && "Primary"}
                              </span>
                            </p>
                          </Grid>
                        );
                      })}
                    </Typography>
                  </Grid>
                )}

                <Grid className="booking-reservation-info">
                  <h3>{translate("ra.fieldName.basicDetails")}</h3>
                  <Typography component="div" className="authorised-details">
                    <Grid>
                      <h6>{translate("ra.fieldName.firstName")}</h6>
                      <p>{formData?.first_name}</p>
                    </Grid>
                    <Grid>
                      <h6>{translate("ra.fieldName.lastName")}</h6>
                      <p>{formData?.last_name}</p>
                    </Grid>
                    <Grid>
                      <h6>{translate("ra.fieldName.mobileNo")}</h6>
                      <p>
                        {formData?.mobile_no_country_code && "+"}
                        {formData?.mobile_no_country_code} {formData?.mobile_no}
                      </p>
                    </Grid>
                    <Grid>
                      <h6>{translate("ra.fieldName.alternativeNo")}</h6>
                      <p>
                        {formData?.alt_mobile_no_country_code > 0 ? "+" : ""}
                        {formData?.alt_mobile_no_country_code || ""}{" "}
                        {formData?.alt_mobile_no || "-"}
                      </p>
                    </Grid>
                    <Grid>
                      <h6>{translate("ra.fieldName.email")}</h6>
                      <ClickAwayListener onClickAway={handleTooltipClose}>
                        <p className="email-text-info">
                          <Tooltip
                            arrow
                            open={openTooltip}
                            title={formData?.email}
                            onClose={handleTooltipClose}
                          >
                            <i
                              className="iconwasalt icon-eye"
                              onClick={handleTooltipOpen}
                              onMouseEnter={handleTooltipOpen}
                              onMouseLeave={handleTooltipClose}
                            />
                          </Tooltip>
                          <em>{formData?.email}</em>
                        </p>
                      </ClickAwayListener>
                    </Grid>
                    <Grid>
                      <h6>{translate("ra.fieldName.nationality")}</h6>
                      <p>{nationalityValue}</p>
                    </Grid>
                    <Grid>
                      <h6>{translate("ra.fieldName.country")}</h6>
                      <p>{countryValue}</p>
                    </Grid>
                  </Typography>
                </Grid>
                <Grid className="booking-reservation-info residence-details-panel">
                  <h3>{translate("ra.fieldName.residenceDetails")}</h3>
                  <Typography component="div" className="authorised-details">
                    <Grid>
                      <h6>{translate("ra.fieldName.address1")}</h6>
                      <p>{formData?.address_line_1}</p>
                    </Grid>
                    <Grid>
                      <h6>{translate("ra.fieldName.address2")}</h6>
                      <p>{formData?.address_line_2 || "-"}</p>
                    </Grid>
                    <Grid>
                      <h6>{translate("ra.fieldName.city")}</h6>
                      <p>{formData?.city}</p>
                    </Grid>
                    <Grid>
                      <h6>{translate("ra.fieldName.country")}</h6>
                      <p>{countryValue}</p>
                    </Grid>
                    <Grid>
                      <h6>{translate("ra.fieldName.postalPoBox")}</h6>
                      <p>{formData?.postal_code_po_box || "-"}</p>
                    </Grid>
                  </Typography>
                </Grid>
                <Grid className="booking-reservation-info">
                  <h3>{translate("ra.fieldName.idDetails")}</h3>
                  <Typography component="div" className="authorised-details">
                    <Grid>
                      <h6>{translate("ra.fieldName.passportNumber")}</h6>
                      <p>{formData?.passport_number}</p>
                    </Grid>
                    <Grid>
                      <h6>{translate("ra.fieldName.passportExpiryDate")}</h6>
                      <p>{formData?.passport_expiry_date}</p>
                    </Grid>
                    <Grid className="documents-info-panel">
                      <h6>{translate("ra.fieldName.document")}</h6>
                      {formData?.documents?.map((document) => {
                        if (document?.doc_type === "passport") {
                          return (
                            <>
                              <a>
                                <img
                                  src={
                                    document?.doc_extension === "pdf"
                                      ? PdfPic
                                      : document?.doc_url
                                  }
                                  onClick={() =>
                                    onClickImage(
                                      document?.doc_url,
                                      document?.doc_extension
                                    )
                                  }
                                />
                              </a>
                              <p>
                                <span>{document?.file_name}</span>
                              </p>
                            </>
                          );
                        }
                      })}
                    </Grid>
                  </Typography>
                  {formData?.id_number && (
                    <Typography
                      component="div"
                      className="review-additional-details"
                    >
                      <h3>{translate("ra.fieldName.additionalIdDetails")}</h3>
                      <Typography
                        component="div"
                        className="authorised-details"
                      >
                        <Grid>
                          <h6>{translate("ra.fieldName.additionIdNumber")}</h6>
                          <p>{formData?.id_number || "-"}</p>
                        </Grid>
                        <Grid>
                          <h6>{translate("ra.fieldName.idExpiryDate")}</h6>
                          <p>{formData?.id_expiry_date || "-"}</p>
                        </Grid>
                        {formData?.documents?.filter(
                          (list) => list?.doc_type === "additional"
                        )?.length > 0 && (
                          <Grid className="documents-info-panel">
                            <h6>{translate("ra.fieldName.document")}</h6>
                            {formData?.documents?.map((document) => {
                              if (document?.doc_type === "additional") {
                                return (
                                  <>
                                    <a>
                                      <img
                                        src={
                                          document?.doc_extension === "pdf"
                                            ? PdfPic
                                            : document?.doc_url
                                        }
                                        onClick={() =>
                                          onClickImage(
                                            document?.doc_url,
                                            document?.doc_extension
                                          )
                                        }
                                      />
                                    </a>
                                    <p>
                                      <span>{document?.file_name}</span>
                                    </p>
                                  </>
                                );
                              }
                            })}
                          </Grid>
                        )}
                      </Typography>
                    </Typography>
                  )}
                </Grid>

                {uids && uids[0] === formData?.id && formData?.units[0].variation_comment && (
                  <Grid className="booking-reservation-info">
                    <h3>{translate("ra.fieldName.comment")}</h3>
                    <div>
                      <p>{formData?.units[0].variation_comment}</p>
                    </div>
                  </Grid>
                )}

                <Grid className="booking-reservation-info booking-reservation-info-last review-delete-panel">
                  <h3>{translate("ra.fieldName.actions")}</h3>
                  <Typography component="div" className="review-delete-section">
                    <Button onClick={() => editCustomer()}>
                      <i className="iconwasalt icon-edit-outline" />
                      {translate("ra.button.editCustomer")}
                    </Button>
                    <Button
                      className="delete-button"
                      onClick={() => setDeletePopup(true)}
                    >
                      <i className="iconwasalt icon-delete" />
                      {translate("ra.button.deleteCustomer")}
                    </Button>
                  </Typography>
                </Grid>
              </>
            </Grid>
            <Grid
              container
              className="company-details-button align-right"
              spacing={0}
            >
              <Button
                variant="outlined"
                onClick={() => onClickBack()}
                className="mobile-only"
              >
                {translate("ra.button.back")}
              </Button>
              <Button variant="contained" onClick={() => onClickSubmit()}>
                {tabs?.length == panelValue
                  ? translate("ra.button.submit")
                  : translate("ra.button.continue")}
              </Button>
            </Grid>
          </Grid>

          {deletePopup && (
            <DeleteCustomerPopup
              first_name={formData?.first_name}
              last_name={formData?.last_name}
              deleteCustomer={deleteCustomer}
              deletePopup={deletePopup}
              setDeletePopup={setDeletePopup}
              loading={deleteLoading}
              showSubtext={tabs?.length > 1 ? true : false}
            />
          )}
          {confirmationPopup && (
            <DialogBox
              openPopup={confirmationPopup}
              setConfirmationPopup={setConfirmationPopup}
              closeBtn={true}
              closeBtnHandler={() => callPostDelete()}
              closeBtnClass="outline-button"
              dialogClass="delete-customer-popup"
              content={translate("ra.content.deletedCustomer")}
            />
          )}

          {openImageDialog && (
            <Dialog
              aria-labelledby="customized-dialog-title"
              className="image-show-popup"
              keepMounted
              open={openImageDialog}
            >
              <i
                className="iconwasalt icon-close cross-button"
                onClick={() => setOpenImageDialog(false)}
              />
              <DialogContent>
                <img src={dialogImage} />
              </DialogContent>
            </Dialog>
          )}
        </>
      )}
    </>
  );
};
export default ReviewIndividualCustomer;
