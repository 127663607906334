export const removeLocalStorage = () => {
    removePartLocalStorage();
    localStorage.removeItem("editPath");
    localStorage.removeItem("previous_url");
    localStorage.setItem("editCustomer", false);
}

export const removePartLocalStorage = () => {
    sessionStorage.removeItem("formStatus");
    localStorage.removeItem("portalBookingId");
    localStorage.removeItem("tabs");
    localStorage.removeItem("unit-reservation");
    localStorage.removeItem("bookingState");
    localStorage.removeItem("bookingId");
}