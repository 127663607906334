import CryptoJS from 'crypto-js'
import { apiServiceRequest } from 'Utils/axiosInstance'

const keyMapping = {
  PageType: 'page_type',
}
const keysToFind = ['LCP', 'CLS', 'FID', 'FCP']
// Function to create API key using CryptoJS.HmacSHA256
const createApiKey = (secretKey, message) => {
  const hash = CryptoJS.HmacSHA256(message, secretKey)
  return hash.toString(CryptoJS.enc.Hex)
}

const eventsSaveInDB = ({ name, delta }, pageType) => {
  const originalObject = {
    [name]: Math.round(delta),
    url: window.location.href,
    PageType: pageType,
  }

  const [foundKey, foundValue] =
    Object.entries(originalObject).find(([key, value]) =>
      keysToFind.includes(key)
    ) || []
  const transformedObject = {
    name: foundKey,
    value: foundValue,
  }

  // Add 'PageType' and 'url' properties
  transformedObject.page_type = originalObject.PageType
  transformedObject.url = originalObject.url
  transformedObject.event = 'webVitals'
  // Check if transformedObject has 'name' property to ensure it's not an empty object
  if ('name' in transformedObject) {
    saveToDatabase(transformedObject)
  }
}

const saveToDatabase = async (bodyObject) => {
  const secretKey = process.env.REACT_APP_SECRET_KEY || 'ahjdyui67'

  try {
    const jsonString = JSON.stringify(bodyObject)
    const apiKey = createApiKey(secretKey, jsonString)
    // Make the API request without handling the response
    await apiServiceRequest({
      url: `/create-webvital-score`,
      headers: { apikey: apiKey, 'Content-Type': 'application/json' },
      method: 'POST',
      data: bodyObject,
    })
  } catch (error) {
    console.error('Error saving data:', error.message)
  }
}

// Function to transform data based on key
const transformData = (dataList, key) => {
  return dataList.map((item) => ({
    id: item[key],
    name: item[key],
  }))
}

//Manage class name for countery flag in
const manageFlagClass = () => {
  // Define an array of class names to check
  const classNames = ['ca', 'pr']
  classNames.map((className) => {
    // Select the element with the respective class
    const phoneFieldElement = document.querySelector(
      `.phone-field-${className} .flag.us`
    )

    // Check if the element is found

    if (phoneFieldElement) {
      phoneFieldElement.classList.remove('us')
      phoneFieldElement.classList.add(className)
    }
  })
}
export { eventsSaveInDB, transformData, manageFlagClass }
