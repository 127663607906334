import React, { useEffect, useState } from "react";
import { Grid, Typography, Stack, Button } from "@mui/material";
import isRead from "Utils/ui-lock-status";
import { useTranslate } from "react-admin";
import ErrorComponent from "../ErrorComponent";
import { docTypes, docFileTypes } from "Utils/constantValues";
import { formatSizeUnits } from "Utils/commonFunctions";

const UploadDocumentSection = ({
  watchDoc,
  register,
  resetField,
  title,
  fieldName,
  os,
  errorMsg,
  errors,
  notification,
  fileSizeInKb = false,
  hide = false,
  onClick,
  onClickHandler,
}) => {
  const translate = useTranslate();

  useEffect(() => {
    if (watchDoc?.length) {
      let fileSizeValue = watchDoc[0]?.size || 0;
        //checking for file type
        if (docTypes.includes(watchDoc[0]?.type) || docFileTypes.includes(watchDoc[0]?.doc_extension)) {
          // checking for file size
          if (Math.round(fileSizeValue / 1024) <= 10240) {
            watchDoc?.length;
          } else {
            notification("ra.notification.fileSizeCheck", { type: "error" });
            resetField(fieldName);
          }
      } else {
        notification("ra.notification.fileTypeCheck", { type: "error" });
        resetField(fieldName);
      }
    }
  }, [watchDoc]);

  const handleClick = (watchDoc) => {
    
    onClickHandler(watchDoc);
  };

  return (
    <>
      <Grid item xs={12}>
        {!hide &&
          <Typography className="form_label" variant="subtitle1" mb={1}>
            {title}
            <span style={{ color: "#EF4349" }}>*</span>
          </Typography>
        }

        {/* When there is no file */}
        {(watchDoc?.length === 0 ||
          !watchDoc) && (
            <Button
              variant="contained"
              component="label"
              fullWidth
              inputProps={{
                readOnly: isRead(os),
              }}
            >
              <i className="iconwasalt icon-add-file" ></i>
              {translate("ra.fieldName.uploadDoc")}
              <input
                type="file"
                name={fieldName}
                hidden
                {...register(fieldName, {
                  required: watchDoc?.length === 0 && errorMsg
                })
                }
                accept="application/pdf, image/png, image/jpeg"
                onClick={onClick}
              />
            </Button>
          )}

        {/* When user has uploaded a file */}
        {watchDoc?.length > 0 &&
          (
            <Stack className="document-add-panel">
              <Typography className="form_label" variant="subtitle1" mb={1}>
                <a
                  href="#"
                  target="blank"
                  onClick={(e) => {
                    e.preventDefault(); // Prevent default anchor behavior
                    handleClick(watchDoc[0]);
                  }}
                >
                  {watchDoc[0]?.name}
                </a>
              </Typography>
              <Typography>
                {fileSizeInKb && formatSizeUnits(watchDoc[0].size)}
              </Typography>
              <i
                className={`iconwasalt icon-delete ${isRead(os) && "display-none"}`}
                onClick={() => {
                  resetField(fieldName);
                }}
              >
              </i>
            </Stack>
          )}
      </Grid>
      {errors?.message && (
        <ErrorComponent className="Mui-error" errors={errors?.message} />
      )}
    </>
  );
};

export default UploadDocumentSection;
