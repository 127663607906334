import { apiServiceRequest } from "Utils/axiosInstance";
import { getUserToken } from "Utils/tokenProvider";

//Register Service
const registerService = async (
  isRead,
  os,
  setLoading,
  data,
  notify,
  redirect,
  redirectPage,
  setData
) => {
  const token = await getUserToken(notify);
  
  if (isRead && isRead(os)) {
    redirect(redirectPage);
  }

  let urlObject = {
    url: "/register",
    method: "post",
    data: data,
    notify: notify,
  }

  if(token) {
    urlObject.headers = {
      Authorization :`Bearer ${token}`
    }
  }

  if (isRead === "" || !isRead(os)) {
    const res = await apiServiceRequest(urlObject);
    if(res) {
      if (res?.code === "success") {
        setData && setData(res?.data);
        redirect(redirectPage);
      } else {
        notify(res.message, { type: "error" });
      }
    }
  }
  setLoading(false);
};

export default registerService;
