import React, { useEffect, useState } from "react";
import { useRedirect, useStoreContext, useStore, useTranslate } from "react-admin";
import { useForm } from "react-hook-form";
import { Button, Container, TextField, Grid, Typography } from "@mui/material";
import Loader from "Components/Loader";
import { setUserToken } from "Utils/tokenProvider";
import { companyNamePattern, emailPattern, passwordPattern } from "Utils/regex-patterns";
import registerService from "Services/registerService";
import InputField from "Components/InputField";
import HeaderButton from "Components/HeaderButton";

const CreateAccount = ({ notification }) => {
  const step = 1;
  const [user_token, setUser_Token] = useStore("user_token");
  const [formData, setFormData] = useStore("formData");
  const [loading, setLoading] = useState(false);
  const [user_id, setUser_id] = useStore("user_id");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const redirect = useRedirect();
  const translate = useTranslate();
  const store = useStoreContext();
  const user_type = store.getItem("user_type");
  
  useEffect(() => {
    if (!user_type || user_type != 2) {
      redirect("/login");
    }
    if (step === 1) {
      setUser_id("");
    }
  }, []);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const setData = (resData) => {
    setUser_id(resData?.id);
    setUser_Token(resData?.token);
    setUserToken(resData?.token);
  };

  const onSubmit = async (data) => {
    
    setIsSubmitting(true); // Disable the button after the first click

    const { email, password, c_password, company_name } = data;
    let apiRequestData = {
      step,
      user_type,
      email,
      password,
      c_password,
      company_name,
    };
    setFormData(apiRequestData);
    try{
      await registerService(
        "",
        "",
        setLoading,
        apiRequestData,
        notification,
        redirect,
        "/register-agency/personal",
        setData
      );
      setIsSubmitting(false); 
    }catch(error){
      setIsSubmitting(false); 
    }
    
  };

  return (
    <>
      {loading && <Loader />}
      <Container className="company-container new-fix-container">
        <Container className="company-details">
          <HeaderButton notification={notification} classname={"only-back"} />
          <Grid className="form-panel" sx={{ width: "100%" }}>            
            <Typography variant="h3">{translate("ra.pageTitle.createAccount")}</Typography>
            <Typography variant="h4">{translate("ra.pageTitle.PersonalDetails")}</Typography>
            <Grid container spacing={0}>
              <InputField 
                id="outlined-basic-company_name"
                name={translate("ra.fieldName.companyName")}
                register={register} 
                errors={errors} 
                state="company_name"
                message={translate("ra.validation.mandatoryField")}
                pattern={{
                  value: companyNamePattern,
                  message: translate("ra.validation.mandatoryField")
                }}
                placeholder={translate("ra.placeholder.companyName")}
              />
            </Grid>
            <Grid container spacing={0}>
              <InputField 
                id="outlined-basic-email"
                name={translate("ra.fieldName.companyAdminEmail")}
                register={register} 
                errors={errors} 
                type="email"
                state="email"
                message={translate("ra.validation.validEmail")}
                pattern={{
                  value: emailPattern,
                  message: translate("ra.validation.validEmail")
                }}
                placeholder={translate("ra.placeholder.email")}
              />
            </Grid>
            <Typography variant="h4">Password Details</Typography>
            <Grid container spacing={0}>
              <InputField 
                id="password"
                name={translate("ra.fieldName.password")}
                register={register} 
                errors={errors} 
                type="password"
                state="password"
                message={translate("ra.validation.passwordError")}
                placeholder={translate("ra.placeholder.password")}
                pattern={{
                  value: passwordPattern,
                  message: translate("ra.validation.passwordCheck")
                }}
              />
            </Grid>

            <Grid container spacing={0}>
              <InputField 
                id="confirmPassword"
                name={translate("ra.fieldName.confirmPassword")}
                register={register} 
                errors={errors} 
                type="password"
                state="c_password"
                message={translate("ra.validation.confirmError")}
                placeholder={translate("ra.placeholder.confirmPassword")}
                validate={(val) => {
                  if (watch("password") !== val) {
                    return translate("ra.validation.passwordMatch");
                  }
                }}
              />
            </Grid>
            <Grid container className="company-details-button" spacing={0}>              
              <Button variant="contained" onClick={handleSubmit(onSubmit)} disabled={isSubmitting}>
                {translate("ra.button.createAccount")}
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </>
  );
};

export default CreateAccount;
