import React, { useEffect, useState } from 'react'
import {
  useRedirect,
  useStoreContext,
  useStore,
  useTranslate,
} from 'react-admin'
import { useForm } from 'react-hook-form'
import { Container, Grid, Button, Typography, FormControl } from '@mui/material'
import Loader from 'Components/Loader'
import isRead from 'Utils/ui-lock-status'
import AutoCompleteComponent from 'Components/Common/AutoComplete'
import DatePickerComponent from 'Components/Common/DatePicker'
import { getDateFormat } from 'Utils/dateFormat'
import { expiryDate, issueDate } from 'Utils/maxMinDate'
import { licensed } from 'Utils/constantValues'
import { textWithSpacePattern } from 'Utils/regex-patterns'
import userDetails from 'Services/userDetailsServices'
import country from 'Services/countryService'
import projectList from 'Services/projectListServices'
import { ProjectDestinationsInterested } from 'Services/Salesforce/projectService'
import registerService from 'Services/registerService'
import InputField from 'Components/InputField'
import Title from 'Components/Title/Title'
import SelectComponent from 'Components/Common/SelectComponent'
import { brokerageStatus } from 'Utils/dataCheck'
import RadioCheckboxComponent from 'Components/Common/RadioCheckboxComponent'
import { addRemoveFromArray } from 'Utils/formArrayValues'
import HeaderButton from 'Components/HeaderButton'

const PersonalDetails = ({ notification }) => {
  const [formData, setFormData] = useStore()
  const [os, setOs] = useState(null)
  const [loading, setLoading] = useState(false)
  const [countryListLoading, setCountryListLoading] = useState(false)
  const [bdmLoading, setBdmLoading] = useState(false)
  const [projectInterestedLoading, setProjectInterestedLoading] =
    useState(false)
  const [bdm, setBdm] = useState([])
  const [projectInterestedList, setProjectInetrestedList] = useState([])
  const [countryList, setCountryList] = useState([])
  const [countryState, setCountryState] = useState(null)
  const [ccode, setccode] = useState(null)
  const [certificateNo, setCertificateNo] = useState(null)

  const step = 2
  const store = useStoreContext()
  const translate = useTranslate()
  const redirect = useRedirect()
  let user_id = store.getItem('user_id')
  let user_type = store.getItem('user_type')
  const {
    register,
    handleSubmit,
    watch,
    clearErrors,
    setValue,
    formState: { errors },
    control,
  } = useForm()

  const setOracleStatus = (resData) => {
    resData?.oracle_status ? setOs(resData?.oracle_status) : setOs('-')
  }

  useEffect(() => {
    
    ProjectDestinationsInterested(
      setProjectInetrestedList,
      setProjectInterestedLoading,
      notification,
      false
    )
    country(setCountryList, setCountryListLoading, notification)
    userDetails(setLoading, setFormData, setOracleStatus, notification)
  }, [])

  const projectDestinationHandler = (e) => {
    const projectData = formData?.project_destinations_interested || []
    const destination = [...projectData]
    addRemoveFromArray(
      e.target.value,
      destination,
      setFormData,
      'project_destinations_interested'
    )
  }

  const onSubmit = async (data) => {
    if (!user_type) {
      user_type = Number(localStorage.getItem('user_type'))
    }
    if (!user_id) {
      user_id = Number(localStorage.getItem('user_id'))
    }

    let {
      trade_license_number,
      trade_license_issue_date,
      trade_license_expiry_date,
      city_registration,
      brokerage_certificate_number,
      brokerage_certificate_issue_date,
      brokerage_certificate_expiry_date,
      pdi,
      license_type,
      business_development_manager,
      tax_registration_number,
    } = data

    if (isRead(os)) {
      pdi = formData?.project_destinations_interested
    } else {
      pdi = Array.isArray(pdi) ? [...pdi] : [pdi]
    }
    const project_destinations_interested = pdi?.join(',')

    const formated_brokerage_certificate_issue_date =
      brokerage_certificate_issue_date
        ? getDateFormat(brokerage_certificate_issue_date)
        : null
    const formated_brokerage_certificate_expiry_date =
      brokerage_certificate_expiry_date
        ? getDateFormat(brokerage_certificate_expiry_date)
        : null
    const formated_trade_license_issue_date = getDateFormat(
      trade_license_issue_date
    )
    const formated_trade_license_expiry_date = trade_license_expiry_date
      ? getDateFormat(trade_license_expiry_date)
      : ''

    let apiRequestData = {
      step,
      user_type,
      user_id,
      trade_license_number: trade_license_number?.trim(),
      trade_license_issue_date: formated_trade_license_issue_date,
      trade_license_expiry_date: formated_trade_license_expiry_date,
      city_registration: city_registration?.trim(),
      country_registration: ccode,
      brokerage_certificate_number: brokerage_certificate_number?.trim(),
      brokerage_certificate_issue_date:
        formated_brokerage_certificate_issue_date,
      brokerage_certificate_expiry_date:
        formated_brokerage_certificate_expiry_date,
      tax_registration_number: tax_registration_number?.trim(),
      license_type,
      project_destinations_interested,
      business_development_manager,
    }
    registerService(
      isRead,
      os,
      setLoading,
      apiRequestData,
      notification,
      redirect,
      '/register-agency/personnel'
    )
    setLoading(false)
  }

  useEffect(() => {
    if (brokerageStatus(certificateNo)) {
      setValue('brokerage_certificate_expiry_date', null)
      setValue('brokerage_certificate_issue_date', null)
      clearErrors('brokerage_certificate_issue_date')
      clearErrors('brokerage_certificate_expiry_date')
    }
  }, [certificateNo])

  useEffect(() => {
    if (formData?.country_registration) {
      const country = countryList?.find(
        (country) => country.country_code == formData?.country_registration
      )
      setCountryState(country)
      setccode(formData?.country_registration)
    }
  }, [formData?.country_registration, countryList])

  
  return (
    <>
      {(loading ||
        countryListLoading ||
        bdmLoading ||
        projectInterestedLoading) && <Loader />}
      {formData && (
        <Container className="company-container new-fix-container">
          <Container className="company-details">
            <HeaderButton
              notification={notification}
              os={os}
              classname={'only-logout'}
            />
            <Grid className="form-panel" sx={{ width: '100%' }}>
              <Typography
                sx={{
                  textAlign: 'center',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  marginBottom: '20px',
                }}
                variant="h3"
              >
                {translate('ra.pageTitle.companyDetails')}
              </Typography>

              {/* Trade License Number */}
              <Grid container spacing={0}>
                <InputField
                  name={translate('ra.fieldName.tradeNo')}
                  register={register}
                  errors={errors}
                  defaultValue={formData?.trade_license_number || ''}
                  id="outlined-basic-trade_license_number"
                  readOnly={isRead(os)}
                  state="trade_license_number"
                  sx={{ width: '100%', marginBottom: '20px' }}
                  message={translate('ra.validation.validTrade')}
                  placeholderHide={true}
                />
              </Grid>

              {/* city_registration */}
              <Grid container spacing={0}>
                <InputField
                  name={translate('ra.fieldName.regCity')}
                  register={register}
                  errors={errors}
                  defaultValue={formData?.city_registration || ''}
                  id="city_registration"
                  readOnly={isRead(os)}
                  state="city_registration"
                  sx={{ width: '100%', marginBottom: '20px' }}
                  placeholderHide={true}
                />
              </Grid>

              {/* country_registration */}
              <Grid container className="new-select-class" spacing={0}>
                <AutoCompleteComponent
                  title={translate('ra.fieldName.regCountry')}
                  value={countryState}
                  onChange={(e, newInputValue) => {
                    setCountryState(newInputValue)
                    setccode(newInputValue?.country_code)
                    clearErrors('country_registration')
                  }}
                  options={countryList}
                  getOptionLabel={(option) => option?.country_name || ''}
                  name="country_registration"
                  placeholder={translate('ra.placeholder.country')}
                  errors={errors?.country_registration}
                  register={register}
                  os={os}
                />
              </Grid>

              <Grid
                className="new-select-class new-select-label-space"
                container
                spacing={0}
              >
                <Typography
                  className="checkbox_text"
                  variant="subtitle1"
                  align="left"
                >
                  {translate('ra.fieldName.licensedOption')}
                  <span style={{ color: '#EF4349' }}>*</span>
                </Typography>
                <FormControl fullWidth sx={{ mb: 4 }}>
                  <Title
                    name="license_type"
                    defaultValue={formData?.license_type || ''}
                    dropdownValue={licensed}
                    register={register}
                    errors={errors}
                    className="full-width ml0 mb0"
                    inputProps={{
                      readOnly: isRead(os),
                    }}
                    sx={{
                      '& legend': { display: 'none' },
                      width: '100%',
                      marginBottom: '0',
                    }}
                    labelhide={true}
                    message={translate('ra.validation.pleaseChoose')}
                  />
                </FormControl>
              </Grid>

              {/* trade_license_issue_date */}
              <Grid container className="clear-left" spacing={0}>
                <DatePickerComponent
                  title={translate('ra.fieldName.tradeIssueDate')}
                  control={control}
                  name="trade_license_issue_date"
                  value={formData?.trade_license_issue_date}
                  maxDate={issueDate}
                  errors={errors?.trade_license_issue_date}
                  os={os}
                />
              </Grid>

              {/* trade_license_expiry_date */}
              <Grid container spacing={0}>
                <DatePickerComponent
                  title={translate('ra.fieldName.tradeExpiryDate')}
                  control={control}
                  name="trade_license_expiry_date"
                  value={formData?.trade_license_expiry_date}
                  no_validation={true}
                  minDate={expiryDate}
                  errors={errors?.trade_license_expiry_date}
                  os={os}
                />
              </Grid>

              {/* brokerage_certificate_number */}
              <Grid container spacing={0}>
                <InputField
                  name={translate('ra.fieldName.brokerageNo')}
                  register={register}
                  errors={errors}
                  defaultValue={
                    formData.brokerage_certificate_number !== 0
                      ? formData.brokerage_certificate_number
                      : 'NA'
                  }
                  id="outlined-basic-brokerage_certificate_number"
                  placeholder={translate('ra.placeholder.brokerageNo')}
                  readOnly={isRead(os)}
                  onChange={(e) => setCertificateNo(e.target.value)}
                  state="brokerage_certificate_number"
                  message={translate('ra.validation.validbrokerageNo')}
                  sx={{ width: '100%', marginBottom: '20px' }}
                />
              </Grid>

              {/* brokerage_certificate_issue_date */}
              <Grid container spacing={0}>
                <DatePickerComponent
                  title={translate('ra.fieldName.brokergeIssueDate')}
                  control={control}
                  name="brokerage_certificate_issue_date"
                  value={formData?.brokerage_certificate_issue_date}
                  hide={brokerageStatus(watch('brokerage_certificate_number'))}
                  maxDate={issueDate}
                  errors={errors?.brokerage_certificate_issue_date}
                  os={os}
                />
              </Grid>

              {/* brokerage_certificate_expiry_date */}
              <Grid container spacing={0}>
                <DatePickerComponent
                  title={translate('ra.fieldName.brokergeExpiryDate')}
                  control={control}
                  name="brokerage_certificate_expiry_date"
                  value={formData?.brokerage_certificate_expiry_date}
                  hide={brokerageStatus(watch('brokerage_certificate_number'))}
                  minDate={expiryDate}
                  errors={errors?.brokerage_certificate_expiry_date}
                  os={os}
                />
              </Grid>

              {/* tax_registration_number */}
              <Grid container spacing={0}>
                <InputField
                  name={translate('ra.fieldName.taxNo')}
                  register={register}
                  errors={errors}
                  defaultValue={formData.tax_registration_number || ''}
                  placeholder={translate('ra.placeholder.taxNo')}
                  id="outlined-basic-tax_registration_number"
                  readOnly={isRead(os)}
                  state="tax_registration_number"
                  message={translate('ra.validation.validTaxNo')}
                  sx={{ width: '100%', marginBottom: '20px' }}
                />
              </Grid>

              <Grid
                container
                className="full-section checkbox-panel"
                spacing={0}
              >
                <RadioCheckboxComponent
                  title={translate('ra.fieldName.projectInterest')}
                  name="pdi"
                  radioValues={projectInterestedList}
                  register={register}
                  errors={errors?.pdi?.message}
                  defaultValue={formData?.project_destinations_interested}
                  className="personal"
                  readOnly={isRead(os)}
                  requiredField={translate('ra.validation.selectOptions')}
                  onChange={(e) => projectDestinationHandler(e)}
                  controlClass="checkbox_total-panel ml0"
                  formClass="radio_text"
                  dataValue={formData?.project_destinations_interested || []}
                  dataName="name"
                />
              </Grid>

              {/* business_development_manager */}
              <Grid container spacing={0}>
                <InputField
                  name={translate('ra.fieldName.businessManager')}
                  register={register}
                  errors={errors}
                  defaultValue={formData?.business_development_manager || ''}
                  placeholder={translate('ra.placeholder.businessManager')}
                  id="outlined-basic-business_development_manager"
                  readOnly={isRead(os)}
                  state="business_development_manager"
                  sx={{ width: '100%', marginBottom: '20px' }}
                  required={false}
                  hide={true}
                  pattern={{
                    value: textWithSpacePattern,
                    message: translate('ra.validation.validInput'),
                  }}
                />
              </Grid>

              {/* Buttons */}
              <Grid
                container
                className="company-details-button justify-right"
                spacing={0}
              >
                <Button variant="contained" onClick={handleSubmit(onSubmit)}>
                  {translate('ra.button.next')}
                </Button>
              </Grid>
            </Grid>
          </Container>
        </Container>
      )}
    </>
  )
}

export default PersonalDetails
