import React, { useState } from "react";
import { Slider } from "@material-ui/core";
import { useTranslate } from "react-admin";

export const SingleRangeSlider = ({
  defaultValue,
  max,
  min,
  currency,
  data,
  setData,
  item,
  ownership,
  setOwnership,
  index,
  keys,
  notification
}) => {
  const [sliderValue, setSliderValue] = useState(defaultValue);
  const translate = useTranslate();

  const onChangeSliderValue = (event, value) => {
    const unitArray = [...data];
    const indexArray = unitArray?.map((unit, index) => {
      if (
        unit?.customer_id === item?.customer_id &&
        item?.unit_id === unit?.unit_id
      )
        return index;
    });
    unitArray[indexArray.join("")]["ownership_percentage"] = value;
    setData(unitArray);
    setSliderValue(value);

    function updateOrAddOwnership(ownership, keys, index, value) {
      const updatedOwnership = { ...ownership };
      if (!updatedOwnership[keys]) {
        updatedOwnership[keys] = [];
      }

      const indexToUpdate = updatedOwnership[keys].findIndex(
        (item, idx) => idx === index
      );

      if (indexToUpdate !== -1) {
        updatedOwnership[keys][indexToUpdate].sliderValue = value;
      } else {
        updatedOwnership[keys].push({
          id: index,
          type: keys[0],
          sliderValue: value,
        });
      }

      const sum = updatedOwnership[keys].reduce(
        (total, item) => total + item.sliderValue,
        0
      );
      
      updatedOwnership[`${keys}_sum`] = sum;
      return updatedOwnership;
    }

    const updatedOwnership = updateOrAddOwnership(
      ownership,
      keys,
      index,
      value
    );
    setOwnership(updatedOwnership);
  };

  return (
    <>
      {currency && (<span> {currency} {sliderValue}</span>)}
      <Slider
        defaultValue={defaultValue}
        aria-label="Default"
        valueLabelDisplay="off"
        step={1}
        onChange={(event, value) => {
          onChangeSliderValue(event, value);
        }}
        max={max}
        min={min}
      />
      {currency && (
        <span>
          {currency} {min}
        </span>
      )}
      {currency && (
        <span>
          {currency} {max}
        </span>
      )}
      {!currency && (
        <span>
          {sliderValue}&#37;
        </span>
      )}
    </>
  );
};
