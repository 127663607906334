import React, { useEffect } from 'react';
import { useTranslate } from "react-admin";
import { Grid } from "@mui/material";
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';import InputField from "Components/InputField";
import { numberTextPattern } from "Utils/regex-patterns";
import AutoCompleteComponent from "Components/Common/AutoComplete";
import { Checkbox } from '@material-ui/core';
import { getLeadById } from 'Services/leadService';

const ResidentialDetails = ({
  register,
  errors,
  formData,
  clearErrors,
  countryState,
  setCountryState,
  setccode,
  countryList,
  customerType,
  panelValue,
  addressCheck,
  setAddressCheck,
  checkLoading,
  setCheckLoading,
  tabs,
  setValue,
  notification,
  portal_booking_id,
  partial,
  setPartial,
  watch,
  residenceDetails,
  setResidenceDetails,
  ccode
}) => {
  const translate = useTranslate();
  useEffect(() => {
    if (formData?.country) {
      const country = countryList?.find(
        (country) => country.country_code == formData?.country
      );
      setCountryState(country);
      setccode(formData?.country);
    }
  }, [formData?.country, countryList]);

  const setResidenceValues = (res) => {
    setResidenceDetails(res);
    setValue("address_line_1", res?.address_line_1);
    setValue("address_line_2", res?.address_line_2);
    setValue("city", res?.city);
    setValue("postal_code_po_box", res?.postal_code_po_box);
    const country = countryList?.find(
      (country) => country.country_code == res?.country
    );
    setCountryState(country);
    setccode(country?.country_code);
  }

  const onclickAddressCheck = () => {
    if (customerType === "1") {
      if (!addressCheck) {
        tabs[panelValue - 1].check = true;
        setPartial(false);
        getLeadById(setCheckLoading, tabs[panelValue - 2]?.id, "", notification, "individual", "", portal_booking_id, "", true, setResidenceValues)
      }
      else {
        tabs[panelValue - 1].check = false;
        if (formData) {
          setResidenceValues(formData)
        }
        else {
          setResidenceValues(null)
        }
        setPartial(false);
      }
      localStorage.setItem("tabs", JSON.stringify(tabs));
      setAddressCheck(prev => !prev)
    }
  }

  useEffect(() => {
    if (customerType === "1" && addressCheck) {
      if ((watch("address_line_1")?.length && watch("address_line_1") != residenceDetails?.address_line_1)
        || (watch("address_line_2")?.length && watch("address_line_2") != residenceDetails?.address_line_2)
        || (watch("city")?.length && watch("city") != residenceDetails?.city)
        || (watch("postal_code_po_box")?.length && watch("postal_code_po_box") != residenceDetails?.postal_code_po_box)
        || (ccode && ccode !== residenceDetails?.country)
      ) {
        setPartial(true);
      }
    }
  }, [watch("address_line_1"), watch("address_line_2"), watch("city"), watch("postal_code_po_box"), ccode])

  return (
    <>
      {panelValue > 1 && <span className="residence-checkbox">
        {partial
          ? <IndeterminateCheckBoxIcon onClick={() => onclickAddressCheck()} />
          : <Checkbox checked={addressCheck} onClick={() => onclickAddressCheck()} />
        }
        {translate("ra.content.checkboxContent")}</span>}
      {/* Address Line 1*/}
      <Grid>
        <InputField
          name={customerType === "1"
            ? translate("ra.fieldName.address1")
            : translate("ra.fieldName.company_address_line1")}
          register={register}
          errors={errors}
          id="address1"
          className="mb15"
          state="address_line_1"
          placeholderHide={true}
          message={
            customerType === "1"
              ? translate("ra.validation.validAddress")
              : translate("ra.validation.validCompanyAddress")
          }
          defaultValue={
            customerType === "1"
              ? formData?.address_line_1
              : formData?.company_address_line_1
          }
          sx={{ width: "100%", marginBottom: "20px" }}
        />
      </Grid>
      {/* Address Line 2*/}
      <Grid>
        <InputField
          name={customerType === "1"
            ? translate("ra.fieldName.address2")
            : translate("ra.fieldName.company_address_line2")
          }
          register={register}
          errors={errors}
          id="address_line_2"
          className="mb15"
          required={false}
          state="address_line_2"
          placeholderHide={true}
          defaultValue={
            customerType === "1"
              ? formData?.address_line_2
              : formData?.company_address_line_2
          }
          hide={true}
          sx={{ width: "100%", marginBottom: "20px" }}
        />
      </Grid>
      {/* city */}
      <Grid>
        <InputField
          register={register}
          errors={errors}
          defaultValue={formData?.city || ""}
          id="city"
          name="city"
          className="mt0"
          state="city"
          placeholderHide={true}
          message={translate("ra.validation.enterCity")}
          sx={{ width: "100%", marginBottom: "20px" }}
        />
      </Grid>
      {/* Country */}
      <Grid container className="new-select-class" spacing={0}>
        <AutoCompleteComponent
          title={translate("ra.fieldName.country")}
          value={countryState}
          onChange={(e, newInputValue) => {
            setCountryState(newInputValue);
            setccode(newInputValue?.country_code);
            clearErrors("country");
          }}
          readOnly={false}
          options={countryList}
          getOptionLabel={(option) => option?.country_name || ""}
          name="country"
          placeholderHide={true}
          errors={errors?.country}
          register={register}
          message={
            customerType === "1"
              ? translate("ra.validation.selectCountry")
              : translate("ra.validation.validCountry")
          }
        />
      </Grid>
      {/* Postal Code */}
      <Grid>
        <InputField
          name={translate("ra.fieldName.postalOrPO")}
          register={register}
          errors={errors}
          defaultValue={formData?.postal_code_po_box || ""}
          id="postal_code"
          state="postal_code_po_box"
          placeholderHide={true}
          pattern={{
            value: numberTextPattern,
            message: translate("ra.validation.validPostalPO"),
          }}
          message={translate("ra.validation.validPostalPO")}
        />
      </Grid>
    </>
  )
}

export default ResidentialDetails;
