import React from 'react';
import {
  Typography
} from "@mui/material";
import BackButton from 'Components/BackButton';
import LogoutButton from 'Components/LogoutButton/LogoutButton';

const HeaderButton = ({ notification, os, classname }) => {
  return (
    <Typography variant="div" className={`form-back-button ${classname}`} >
      <BackButton />
      <LogoutButton notification={notification} os={os} />
    </Typography>
  )
}

export default HeaderButton;