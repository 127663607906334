import React from 'react';
import Checkbox from '@mui/material/Checkbox';

export default function AccordianCheckbox({ customerId, is_primary, primary, setPrimary, item, idx, handleChange }) {

    return (
        <Checkbox
            checked={((primary[idx]?.unit_no === item?.unit_no) && (primary[idx]?.customerId === item?.customer_id)) ? true : false}
            onClick={() => handleChange(customerId, item?.unit_no)}
            inputProps={{ 'aria-label': 'controlled' }}
            label="Primary"
        />
    );
}