import React, { useEffect } from 'react';
import { Grid } from "@mui/material";
import InputField from "Components/InputField";
import { useTranslate } from "react-admin";
import PhoneInputComponent from "Components/Common/PhoneInput";
import { emailPattern } from "Utils/regex-patterns";
import AutoCompleteComponent from "Components/Common/AutoComplete";

const BasicDetailsForm = ({
    register,
    errors,
    formData,
    nationalityList,
    nationalityState,
    setNationalityCode,
    setNationalityState,
    clearErrors,
    setAlternateMobilePhoneNumber,
    setAlternateMobilePhoneNumberCountryCode,
    setCountryCode,
    setMobilePhoneNumber,
    alternateMobilePhoneNumber,
    phoneErrors,
    mobilePhoneNumber,
    setError,
    setPhoneErrors,
    customerType
}) => {

    const translate = useTranslate();

    useEffect(() => {
        if (formData?.nationality || formData?.authorised_nationality) {
            const nation = nationalityList?.find(
                (nation) => nation?.code == formData?.nationality || nation?.code == formData?.authorised_nationality
            );
            setNationalityState(nation);
            setNationalityCode(nation?.code);
        }
    }, [formData?.nationality, nationalityList]);

    useEffect(() => {
        if (alternateMobilePhoneNumber && alternateMobilePhoneNumber?.length < 6) {
            validatePhone(
                "alt_mobile_no",
                alternateMobilePhoneNumber,
                translate("ra.validation.validTelephoneNo")
            );
        } else {
            clearErrors("alt_mobile_no");
        }
    }, [alternateMobilePhoneNumber]);

    useEffect(() => {
        if (phoneErrors) {
            validatePhone("mobile_no", mobilePhoneNumber);
        }
    }, [phoneErrors]);

    const validatePhone = (
        fieldName,
        mobileNumber,
        message = translate("ra.validation.validMoNumber"),
    ) => {
        if (!mobileNumber || mobileNumber?.length < 6) {
            setError(fieldName, { type: "required", message: message });
        } else {
            clearErrors(fieldName);
            setPhoneErrors(false);
        }
    };

    const handlePhoneNumberValidation = (value, data) => {
        const rawnumber = value.slice(data.dialCode.length);
        const dialCode = data.dialCode;
        validatePhone("mobile_no", rawnumber);
        setMobilePhoneNumber(rawnumber);
        setCountryCode(dialCode);
    };

    const handleAltPhoneNumberValidation = (value, data) => {
        const rawnumber = value.slice(data.dialCode.length);
        const dialCode = data.dialCode;
        setAlternateMobilePhoneNumber(rawnumber);
        setAlternateMobilePhoneNumberCountryCode(dialCode);
    };

    return (
        <>
            {/* First name */}
            <Grid spacing={0}>
                <InputField
                    name={translate("ra.fieldName.firstName")}
                    register={register}
                    errors={errors}
                    id="firstName"
                    defaultValue={customerType === "1" 
                    ? formData?.first_name 
                    : formData?.authorised_first_name || formData?.agent_first_name }
                    state="first_name"
                    placeholderHide={true}
                    message={customerType === "1" ? translate("ra.validation.validFirstname") : translate("ra.validation.validAuthorisedFirstName")}
                />
            </Grid>
            {/* last name*/}
            <Grid spacing={0}>
                <InputField
                    name={translate("ra.fieldName.lastName")}
                    register={register}
                    id="lastName"
                    errors={errors}
                    defaultValue={customerType === "1" ? formData?.last_name : formData?.authorised_last_name || formData?.agent_last_name}
                    state="last_name"
                    placeholderHide={true}
                    message={customerType === "1" ? translate("ra.validation.validLastname") : translate("ra.validation.validAuthorisedLastName")}
                />
            </Grid>
             {/* Nationality */}
            {customerType === "2" &&
                <Grid className="new-select-class" container spacing={0}>
                    <AutoCompleteComponent
                        title={translate("ra.fieldName.nationality")}
                        value={nationalityState}
                        onChange={(e, newInputValue) => {
                            setNationalityState(newInputValue);
                            setNationalityCode(newInputValue?.code);
                            clearErrors("nationality");
                        }}
                        options={nationalityList}
                        getOptionLabel={(option) => option?.name || ""}
                        name="nationality"
                        placeholderHide={true}
                        errors={errors?.nationality}
                        register={register}
                        message={translate("ra.validation.selectNationality")}
                    />
                </Grid>
             }
            {/* Mobile Number */}
            <Grid>
                <PhoneInputComponent
                    id="Mobile-No-Typography"
                    title={translate("ra.fieldName.mobileNo")}
                    className={`mobile_no ${errors?.mobile_no?.message ? "mobile_errorBorder" : ""}`}
                    value={customerType === "1" 
                    ? [formData?.mobile_no_country_code, formData?.mobile_no].join() 
                    : formData?.authorised_mobile_no
                    ? [formData?.authorised_mobile_no_country_code, formData?.authorised_mobile_no].join()
                    : [formData?.agent_mobile_no_country_code, formData?.agent_mobile_no].join()}
                    onChange={handlePhoneNumberValidation}
                    errors={errors?.mobile_no?.message}
                />
            </Grid>
            {/* Alternate Mobiel Number */}
            <Grid container spacing={0}>
                <PhoneInputComponent
                    id="Alt-No-Typography"
                    title={translate("ra.fieldName.alternateNo")}
                    className={`${errors?.alt_mobile_no?.message ? "mobile_errorBorder" : ""}`}
                    hide={true}
                    value={customerType === "1" ? [
                        formData?.alt_mobile_no_country_code || "",
                        formData?.alt_mobile_no,
                    ].join() :
                        [
                            formData?.authorised_alt_mobile_no_country_code || "",
                            formData?.authorised_alt_mobile_no,
                        ].join()
                    }
                    onChange={handleAltPhoneNumberValidation}
                    errors={errors?.alt_mobile_no?.message}
                />
            </Grid>
            {/* email */}
            <Grid>
                <InputField
                    name={translate("ra.fieldName.email")}
                    register={register}
                    errors={errors}
                    defaultValue={customerType === "1" ? formData?.email : formData?.authorised_email || formData?.agent_email}
                    state="email"
                    type="email"
                    message={translate("ra.validation.validEmailAdd")}
                    pattern={{
                        value: emailPattern,
                        message: translate("ra.validation.validEmailAdd"),
                    }}
                    placeholderHide={true}
                />
            </Grid>
            {/* Nationality */}
            {customerType === "1" &&
                <Grid className="new-select-class" container spacing={0}>
                    <AutoCompleteComponent
                        title={translate("ra.fieldName.nationality")}
                        value={nationalityState}
                        onChange={(e, newInputValue) => {
                            setNationalityState(newInputValue);
                            setNationalityCode(newInputValue?.code);
                            clearErrors("nationality");
                        }}
                        options={nationalityList}
                        getOptionLabel={(option) => option?.name || ""}
                        name="nationality"
                        placeholderHide={true}
                        errors={errors?.nationality}
                        register={register}
                        message={translate("ra.validation.selectNationality")}
                    />
                </Grid >
            }
        </>
    )
}

export default BasicDetailsForm